// @flow

import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin'
import {
  $getRoot,
  $isParagraphNode,
  $isRangeSelection,
  $isRootNode,
  $isTextNode,
  type EditorState,
  type LexicalNode,
  LineBreakNode,
  ParagraphNode,
  TextNode,
} from 'lexical'
import * as React from 'react'

import { LexicalFocusPlugin } from 'components/editor/focus-plugin'
import { $isLogicalNode, LogicalNode } from 'components/editor/logical-node'
import { LogicalPlugin } from 'components/editor/logical-plugin'
import { LexicalSelectionPlugin } from 'components/editor/selection-plugin'
import { $isTagNode, TagNode } from 'components/editor/tag-node'
import { TagPlugin } from 'components/editor/tag-plugin'
import { lexicalInitState } from 'components/form/fields/editor/lexical-init-state'
import { RemoveLineBreaksOnPastePlugin } from 'components/form/fields/editor/paste-override-lexical-plugin'

import { InlineControls } from './inline-controls'
import { InlineEditorContainer } from './inline-editor.styles'

import { type MessageBuilderField } from 'com.batch/message-builder/models/message-builder-field'
import { InfoPanelPortaled } from 'com.batch/message-builder/ui/components/info-panel/info-panel'

type InlineEditorProps = {
  messageId: string,
  onBlur: ([number, number]) => void,
  placeholder?: string,
  isLoading: boolean,
  field: MessageBuilderField,
  variant: 'a' | 'b',
  canUseTemplating: boolean,
  fullyInline: boolean,
  selection: [number, number],
  onChange: string => void,
  initValue: string,
  hasCharCount: boolean,
}

const getNodeOffset = (
  needle: LexicalNode,
  haystack: LexicalNode,
  offset: number = 0,
  found: boolean = false
): { found: boolean, offset: number, ... } => {
  if (needle === haystack || found) {
    return { found: true, offset }
  }
  if ($isRootNode(haystack) || $isParagraphNode(haystack)) {
    const children = haystack.getChildren()
    return children.reduce(
      ({ found, offset }, childNode) => {
        return getNodeOffset(needle, childNode, offset, found)
      },
      { found, offset }
    )
  }
  if ($isTextNode(haystack) || $isTagNode(haystack) || $isLogicalNode(haystack)) {
    // $FlowExpectedError this code needs a cleanup when the Lexical doc is updated
    return { found: false, offset: offset + haystack?.__text?.length ?? 0 }
  }
  return { found: false, offset: 0 }
}

// Theme
const lexicalTheme = {
  ltr: 'ltr',
  rtl: 'rtl',
  placeholder: 'editor-placeholder',
  paragraph: 'editor-paragraph',
}

export const InlineEditorEditor: React$AbstractComponent<InlineEditorProps, *> = ({
  messageId,
  initValue,
  selection,
  isLoading,
  variant,
  onChange,
  field,
  canUseTemplating,
  fullyInline,
  onBlur,
  placeholder,
  hasCharCount,
}: InlineEditorProps): React.Node => {
  const [value, setValue] = React.useState(initValue)
  const editorStateRef = React.useRef<?EditorState>()

  const onBlurCallback = React.useCallback(() => {
    let selectionTuple = [0, 0]
    if (editorStateRef.current) {
      const sel = editorStateRef.current._selection

      onChange(
        editorStateRef.current.read(() => {
          return $getRoot().getTextContent()
        })
      )
      if ($isRangeSelection(sel)) {
        const { offset } = getNodeOffset(sel.anchor.getNode(), $getRoot())

        selectionTuple = [sel.anchor.offset + offset, sel.focus.offset + offset]
      }
    }
    // @torefacto BE BETTER. Le remove de l'epic inlineEditorConfig déplace le soucis d'attendre que le store ait sa variable personalizationModalOpen à
    // true pour pouvoir récup la position du caret correctement à ce composant. Je déplace le soucis plutot que de le résoudre par manque de temps.
    setTimeout(() => onBlur(selectionTuple), 200)
  }, [onBlur, onChange])

  const configLexical = {
    theme: lexicalTheme,
    namespace: 'BatchLexicalEditor',
    editorState: lexicalInitState(initValue),
    onError: (e: Error) => {
      console.error(e)
    },
    nodes: [LineBreakNode, ParagraphNode, TextNode, TagNode, LogicalNode],
  }

  // Lexical onChange fires on mount, we need to ignore it
  const isMounted = React.useRef(false)
  const onPluginFireChange = React.useCallback(
    editorState => {
      editorStateRef.current = editorState
      editorStateRef.current.read(() => {
        if (isMounted.current) {
          const textValue = $getRoot().getTextContent()
          setValue(textValue)
          onChange(textValue)
        } else {
          isMounted.current = true
        }
      })
    },
    [onChange]
  )

  // ------ render
  return (
    <LexicalComposer initialConfig={configLexical}>
      <InlineEditorContainer $isLoading={isLoading} $fullyInline={fullyInline}>
        <PlainTextPlugin
          ErrorBoundary={LexicalErrorBoundary}
          contentEditable={
            <ContentEditable
              spellCheck={false}
              className="editor-input"
              ariaLabel={field}
              data-testid={`${field ?? ''}-input`}
            />
          }
          placeholder={<div className="editor-placeholder">{placeholder}</div>}
        />
        <OnChangePlugin ignoreSelectionChange={false} onChange={onPluginFireChange} />
        <AutoFocusPlugin />
        <LexicalFocusPlugin onBlur={onBlurCallback} />
        <InfoPanelPortaled
          messageId={messageId}
          variant={variant}
          canUseTemplating={canUseTemplating}
          field={field}
          value={value}
        />
        <HistoryPlugin />
        <LexicalSelectionPlugin selection={selection} />
        <TagPlugin />
        <LogicalPlugin />
        <RemoveLineBreaksOnPastePlugin />
        {fullyInline && (
          <InlineControls
            value={value}
            canUseTemplating={canUseTemplating}
            hasCharCount={hasCharCount}
          />
        )}
      </InlineEditorContainer>
    </LexicalComposer>
  )
}

// @flow
import Immutable, { type Map } from 'immutable'

import {
  OrchestrationAnalyticsFactory,
  type OrchestrationAnalyticsRecord,
} from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'

import { restoreOrchestrationAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/helpers/restore-orchestration-analytics-filters'
import {
  type UpdateAnalyticsFiltersAction,
  type UpdateAnalyticsDateRangeAction,
} from 'com.batch/orchestration-analytics/usecases/analytics-filters'
import { type FetchClicksByUrlActions } from 'com.batch/orchestration-analytics/usecases/fetch-clicks-by-url'
import {
  type FetchOrchestrationStatsByChannelActions,
  type FetchOrchestrationStatsByDayActions,
  type FetchOrchestrationStatsByStepActions,
  type FetchOrchestrationStatsByProviderActions,
  type FetchOrchestrationStatsByClassificationActions,
} from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats.helper'
import { STATUS } from 'constants/common'

type OrchestrationAnalyticsActions =
  | UpdateAnalyticsFiltersAction
  | UpdateAnalyticsDateRangeAction
  | FetchOrchestrationStatsByChannelActions
  | FetchOrchestrationStatsByDayActions
  | FetchOrchestrationStatsByStepActions
  | FetchOrchestrationStatsByProviderActions
  | FetchOrchestrationStatsByClassificationActions
  | FetchClicksByUrlActions

export const orchestrationAnalyticsReducer = (
  state: Map<string, OrchestrationAnalyticsRecord> = Immutable.Map(
    restoreOrchestrationAnalyticsFilters() ?? []
  ),
  action: OrchestrationAnalyticsActions
): Map<string, OrchestrationAnalyticsRecord> => {
  switch (action.type) {
    case 'UPDATE_ANALYTICS_FILTERS':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('filters', action.payload.filters)
      )
    case 'UPDATE_ANALYTICS_DATE_RANGE':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('dateRange', action.payload.dateRange)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL':
      return state.set(
        action.payload.tokens.first(),
        state
          .get(action.payload.tokens.first(), OrchestrationAnalyticsFactory())
          .set('channelsLoadingState', STATUS.LOADING)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_SUCCESS':
      return state.set(
        action.meta.tokens.first(),
        state
          .get(action.meta.tokens.first(), OrchestrationAnalyticsFactory())
          .set('channelsLoadingState', STATUS.LOADED)
          .set('expire', Date.now() + 1000 * 60 * 2) // TTL 2 min
          .setIn(['channels', 'email', 'stats'], action.payload.email)
          .setIn(['channels', 'sms', 'stats'], action.payload.sms)
          .setIn(['channels', 'push', 'stats'], action.payload.push)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_FAILURE':
      return state.set(
        action.meta.tokens.first(),
        state
          .get(action.meta.tokens.first(), OrchestrationAnalyticsFactory())
          .set('channelsLoadingState', STATUS.ERROR)
          .set('expire', Date.now())
      )

    case 'FETCH_ORCHESTRATION_STATS_BY_DAY':
      return state.set(
        action.payload.tokens.first(),
        state
          .get(action.payload.tokens.first(), OrchestrationAnalyticsFactory())
          .set('daysLoadingState', STATUS.LOADING)
      )

    case 'FETCH_ORCHESTRATION_STATS_BY_DAY_SUCCESS':
      return state.set(
        action.meta.tokens.first(),
        state
          .get(action.meta.tokens.first(), OrchestrationAnalyticsFactory())
          .set('daysLoadingState', STATUS.LOADED)
          .setIn(['channels', 'email', 'bounces', 'periods'], action.payload)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_DAY_FAILURE':
      return state.set(
        action.meta.tokens.first(),
        state
          .get(action.meta.tokens.first(), OrchestrationAnalyticsFactory())
          .set('daysLoadingState', STATUS.ERROR)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_STEP':
      return state.set(
        action.payload.tokens.first(),
        state
          .get(action.payload.tokens.first(), OrchestrationAnalyticsFactory())
          .set('stepsLoadingState', STATUS.LOADING)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_STEP_SUCCESS':
      return state.set(
        action.meta.tokens.first(),
        state
          .get(action.meta.tokens.first(), OrchestrationAnalyticsFactory())
          .set('stepsLoadingState', STATUS.LOADED)
          .set('steps', action.payload)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_STEP_FAILURE':
      return state.set(
        action.meta.tokens.first(),
        state
          .get(action.meta.tokens.first(), OrchestrationAnalyticsFactory())
          .set('stepsLoadingState', STATUS.ERROR)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER':
      return state.set(
        action.payload.tokens.first(),
        state
          .get(action.payload.tokens.first(), OrchestrationAnalyticsFactory())
          .set('providersLoadingState', STATUS.LOADING)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER_SUCCESS':
      return state.set(
        action.meta.tokens.first(),
        state
          .get(action.meta.tokens.first(), OrchestrationAnalyticsFactory())
          .set('providersLoadingState', STATUS.LOADED)
          .setIn(['channels', 'email', 'providers'], action.payload)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER_FAILURE':
      return state.set(
        action.meta.tokens.first(),
        state
          .get(action.meta.tokens.first(), OrchestrationAnalyticsFactory())
          .set('providersLoadingState', STATUS.ERROR)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION':
      return state.set(
        action.payload.tokens.first(),
        state
          .get(action.payload.tokens.first(), OrchestrationAnalyticsFactory())
          .set('classificationsLoadingState', STATUS.LOADING)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION_SUCCESS':
      return state.set(
        action.meta.tokens.first(),
        state
          .get(action.meta.tokens.first(), OrchestrationAnalyticsFactory())
          .set('classificationsLoadingState', STATUS.LOADED)
          .setIn(['channels', 'email', 'bounces', 'categories'], action.payload)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION_FAILURE':
      return state.set(
        action.meta.tokens.first(),
        state
          .get(action.meta.tokens.first(), OrchestrationAnalyticsFactory())
          .set('classificationsLoadingState', STATUS.ERROR)
      )
    case 'FETCH_CLICKS_BY_URL':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('linksLoadingState', STATUS.LOADING)
      )
    case 'FETCH_CLICKS_BY_URL_SUCCESS':
      return state.set(
        action.meta.token,
        state
          .get(action.meta.token, OrchestrationAnalyticsFactory())
          .set('linksLoadingState', STATUS.LOADED)
          .setIn(['channels', 'email', 'links'], action.payload)
      )
    case 'FETCH_CLICKS_BY_URL_FAILURE':
      return state.set(
        action.meta.token,
        state
          .get(action.meta.token, OrchestrationAnalyticsFactory())
          .set('linksLoadingState', STATUS.ERROR)
      )

    default:
      return state
  }
}

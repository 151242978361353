// @flow

import Immutable, { type List, type Map } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import request from 'superagent-interface-promise'

import { AppAvatar as Avatar } from 'components/common/avatar'
import {
  Box,
  BoxHeader,
  BoxBody,
  BoxFooter,
  HeaderBoxTitle,
  HeaderBoxActions,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Tag } from 'components/common/tag'
import { Form, InputWrapper, Input, Select } from 'components/form'

import { generateUrl } from 'com.batch.common/router'

import { generateDemoCode } from './../redux/codes'

import { Ellipsis } from '../console.style'
import { type AppRecord } from 'com.batch.redux/_records'
import { normalizeApp } from 'com.batch.redux/app.api'

type DemoCodesFormProps = {
  isOpened: boolean,
  close: () => any,
  ...
}

// select
const fetchApps = (inputValue: string, kind: 'ios' | 'android'): Promise<List<AppRecord>> => {
  return request
    .get(generateUrl('console_api_apps', { query: inputValue, count: 20 }))
    .then(({ body }) => {
      try {
        let appsList = []
        body.entities.map(e => {
          if (e.platform === kind) appsList.push(normalizeApp(e))
        })
        return new Immutable.List().push(...appsList)
      } catch (err) {
        return new Immutable.List()
      }
    })
}

const AppOption = (app: AppRecord) => {
  return (
    <div>
      <Grid template="40px 1fr 1fr">
        <Avatar placeholder={app.name.split(' ')[0]} platform={app.platform} url={app.icon} />
        <Ellipsis>{app.name}</Ellipsis>
        <div style={{ textAlign: 'right' }}>
          <Tag>
            <Ellipsis>{app.apiKey}</Ellipsis>
          </Tag>
        </div>
      </Grid>
    </div>
  )
}

// Component
const DemoCodesFormRaw = ({ isOpened, close }: DemoCodesFormProps) => {
  // ====================== Dispatch
  const dispatch = useDispatch()

  // ====================== Redux state
  const loading = useSelector(state => state.code.loading)

  // ====================== Component state
  const [id, setId] = React.useState<string>('')
  const [desc, setDesc] = React.useState<string>('')
  const [ios, setIos] = React.useState<AppRecord | null>(null)
  const [android, setAndroid] = React.useState<AppRecord | null>(null)
  const [errors, setErrors] = React.useState<Map<string, string>>(Immutable.Map())

  // ====================== Use effect
  React.useEffect(() => {
    if (loading) close()
  }, [loading, close])

  // ====================== Callbacks
  const handleSubmit = React.useCallback(() => {
    let errs = []

    if (id.length === 0) errs.push(['id', "The fields haven't to be empty."])
    if (desc.length === 0) errs.push(['desc', "The fields haven't to be empty."])
    if (ios === null && android === null) {
      errs.push(['ios', 'You have to choose an IOS or / and android app.'])
      errs.push(['android', 'You have to choose an IOS or / and android app.'])
    }

    if (errs.length === 0) {
      let newCode = {
        code: id,
        description: desc,
        ios: ios ? ios.apiKey : '',
        android: android ? android.apiKey : '',
      }
      dispatch(generateDemoCode(newCode))
    }
    setErrors(Immutable.Map(errs))
  }, [id, desc, ios, android, dispatch])

  const onDescChange = React.useCallback(evt => {
    setDesc(evt.target.value)
  }, [])

  const onIdChange = React.useCallback(evt => {
    setId(evt.target.value)
  }, [])

  const onIosChange = React.useCallback(opt => {
    setIos(opt ?? null)
  }, [])
  const onAndroidChange = React.useCallback(opt => {
    setAndroid(opt ?? null)
  }, [])
  const createHandlerLoadOptions = React.useCallback(
    (platform: 'ios' | 'android') => inputValue => {
      return fetchApps(inputValue, platform)
    },
    []
  )
  const optionToString = React.useCallback(app => app?.name ?? '', [])

  // ====================== Render
  return (
    <Popin opened={isOpened} close={close}>
      <Box style={{ width: 600 }}>
        <BoxHeader>
          <HeaderBoxTitle title="Generate a new demo code" />
          <HeaderBoxActions>
            <Button kind="inline" onClick={close} type="button">
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>
        <Form onSubmit={handleSubmit}>
          <BoxBody style={{ padding: 20 }}>
            <InputWrapper label="Code" feedback={errors.get('id')} htmlFor="code">
              <Input
                id="code"
                type="text"
                name="code"
                allowedCharsRegex={/[0-9a-zA-Z_-]/}
                invalid={errors.has('id')}
                value={id}
                onChange={onIdChange}
              />
            </InputWrapper>

            <InputWrapper label="Description" feedback={errors.get('desc')} htmlFor="description">
              <Input
                id="description"
                type="text"
                name="description"
                invalid={errors.has('desc')}
                value={desc}
                onChange={onDescChange}
              />
            </InputWrapper>

            <InputWrapper label="IOS app" feedback={errors.get('ios')}>
              <Select
                optionToString={optionToString}
                isClearable
                isSearchable
                optionMenuHeight={52}
                loadOptions={createHandlerLoadOptions('ios')}
                placeholder="Search an IOS app"
                invalid={errors.has('ios')}
                value={ios}
                onChange={onIosChange}
                optionFormatter={AppOption}
              />
            </InputWrapper>

            <InputWrapper label="Android app" feedback={errors.get('android')}>
              <Select
                optionToString={optionToString}
                isClearable
                isSearchable
                optionMenuHeight={52}
                loadOptions={createHandlerLoadOptions('android')}
                placeholder="Search an android app"
                invalid={errors.has('android')}
                value={android}
                onChange={onAndroidChange}
                optionFormatter={AppOption}
              />
            </InputWrapper>
          </BoxBody>

          <BoxFooter>
            <Button type="submit" kind="primary" intent="action">
              Generate
            </Button>
          </BoxFooter>
        </Form>
      </Box>
    </Popin>
  )
}

export const DemoCodesForm: React.AbstractComponent<DemoCodesFormProps> =
  React.memo<DemoCodesFormProps>(DemoCodesFormRaw)

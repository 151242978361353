// @flow

import { useSelect, useMultipleSelection } from 'downshift'
import Immutable from 'immutable'
import * as React from 'react'

import { usePopper, useWidthObserver } from 'components/_hooks'
import { Button } from 'components/common/button/button.styles'
import { Icon } from 'components/common/svg-icon'
import { SelectDropdown } from 'components/form/fields/select//select-dropdown'
import {
  selectPopperConfig,
  type SelectMenuPropsType,
} from 'components/form/fields/select/select.helper'

import { type FilterSelectMultiProps } from './filter-select-multi'
import { InputButton } from './filter-select.styles'

export function FilterSelectMultiNoSearch<T>({
  options,
  onChange,
  optionToString,
  value,
  placeholder = 'Filter by',
  term,
  optionMenuHeight,
  optionMenuShownCount,
  optionMenuStyle,
  menuStyle,
  style,
  optionFormatter,
  toggleButtonWidth,
  menuOffset = 42,
  isDisabled,
}: FilterSelectMultiProps<T>): React.Node {
  const [isExpanded, setExpanded] = React.useState(false)
  const [showDropdown, setShowDropdown] = React.useState(false)
  const [wrapperRef, popperRef, popperInstance] = usePopper(selectPopperConfig)
  const items = React.useMemo(() => options.toArray(), [options])
  const triggerRef = React.useRef(null)
  React.useEffect(() => {
    if (value.size !== 0) {
      setExpanded(true)
    } else {
      if (!showDropdown) setExpanded(false)
    }

    return () => {}
  }, [value, showDropdown])

  const { addSelectedItem, removeSelectedItem, reset, getDropdownProps, selectedItems } =
    useMultipleSelection({
      initialSelectedItems: value.toArray(),
      onSelectedItemsChange: ({ selectedItems }) => {
        onChange(new Immutable.List().push(...selectedItems))
        popperInstance?.forceUpdate()
      },
    })

  React.useEffect(() => {
    // hack for controlled clear, has to be done manually
    if (value.size === 0 && selectedItems.length > 0) reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const {
    isOpen,
    getItemProps,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    closeMenu,
    setHighlightedIndex,
    openMenu,
  } = useSelect({
    items,
    selectedItem: value ?? null,
    itemToString: optionToString,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges
      switch (type) {
        // Handling the events
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            highlightedIndex: items.findIndex(value => value === changes.selectedItem) ?? 0,
            isOpen: true,
          }
        case useSelect.stateChangeTypes.MenuBlur:
          return {
            ...changes,
            isOpen: false,
          }

        case useSelect.stateChangeTypes.ControlledPropUpdatedSelectedItem:
          return { ...changes, isOpen: state.isOpen }
      }
      return changes
    },
    onStateChange({ type, selectedItem }) {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (selectedItem && !value.includes(selectedItem)) {
            addSelectedItem(selectedItem)
          } else {
            removeSelectedItem(selectedItem)
          }
          // console.log('forced update')
          // popperInstance?.forceUpdate()
          break
        default:
          break
      }
    },
    onIsOpenChange: ({ isOpen }) => {
      if (!isOpen && value.size === 0) {
        setShowDropdown(false)
        setExpanded(false)
      }
      if (isOpen) {
        setExpanded(true)

        // Displays the popper after the expanded animation
        setTimeout(() => {
          setShowDropdown(true)
          popperInstance?.forceUpdate()
        }, 180)
      }
    },
  })

  // Handle  Ref menu
  const buttonProps = getToggleButtonProps(
    getDropdownProps({
      preventKeyAction: isOpen,
      ref: triggerRef,
      style: {
        ...style,
        textAlign: 'left',
        padding: '0 0 0 10px',
        width: toggleButtonWidth ? toggleButtonWidth : isExpanded ? 170 : 120,
      },
      onFocus: () => {
        setExpanded(true)
      },
    })
  )
  const onClear = React.useCallback(
    evt => {
      evt?.stopPropagation()
      reset()
      closeMenu()
      setExpanded(false)
    },
    [closeMenu, reset]
  )
  const isValueSelected = React.useCallback((option: T) => value.includes(option), [value])
  const { innerRef, ...menuProps }: SelectMenuPropsType = getMenuProps({
    ref: popperRef,
    refKey: 'innerRef',
  })
  React.useLayoutEffect(() => {
    if (isOpen) {
      popperInstance?.setOptions(options => ({
        ...options,
        modifiers: [...options.modifiers, { name: 'eventListeners', enabled: true }],
      }))
    } else {
      popperInstance?.setOptions(options => ({
        ...options,
        modifiers: [...options.modifiers, { name: 'eventListeners', enabled: false }],
      }))
    }
  }, [isOpen, popperInstance])
  const width = useWidthObserver(triggerRef, 200)
  return (
    <div ref={wrapperRef}>
      {value.size > 0 ? (
        <Button
          addOn="suffix"
          addOnGap={22}
          isActive={isOpen || value}
          kind="inline"
          {...buttonProps}
        >
          <span style={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
            <Icon icon="filter" style={{ marginRight: 10 }} />
            <span
              style={{
                minWidth: '80px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {term ? term(value.size) : `${value.size} value${value.size > 1 ? 's' : ''}`}
            </span>
          </span>

          <div onClick={onClear} style={{ padding: '8px 12px' }}>
            <Icon icon="close" color="currentColor" />
          </div>
        </Button>
      ) : (
        <InputButton
          style={{ width: 120 }}
          onClick={openMenu}
          disabled={Boolean(isDisabled)}
          addOn="prefix"
          kind="inline"
          isActive={isOpen}
          {...buttonProps}
        >
          <span style={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
            <Icon icon="filter" style={{ marginRight: 10 }} />
            {value.size !== undefined ? placeholder : 'Filter by'}
          </span>
        </InputButton>
      )}

      <SelectDropdown
        {...menuProps}
        innerRef={innerRef}
        width={width + menuOffset}
        optionMenuShownCount={optionMenuShownCount}
        optionMenuHeight={optionMenuHeight}
        getItemProps={getItemProps}
        optionToString={optionToString}
        showCheckbox
        menuStyle={menuStyle}
        optionMenuStyle={optionMenuStyle}
        optionFormatter={optionFormatter}
        options={options}
        isOpen={showDropdown && isOpen}
        highlightedIndex={highlightedIndex}
        setHighlightedIndex={setHighlightedIndex}
        isOptionSelected={isValueSelected}
      />
    </div>
  )
}

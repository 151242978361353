// @flow

import Immutable, { type Map, type List } from 'immutable'
import * as React from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { useSingleParamCallback, useQuery } from 'components/_hooks'
import { ButtonNavLink } from 'components/common/button'
import { ConfirmHighlight } from 'components/common/confirm.styles'
import { TrackingContext, trackEvent } from 'components/common/page-tracker'
import { Pager } from 'components/common/pager'
import {
  Table,
  TableCellOrder,
  TableHeader,
  TableCellHeader,
  TableFooter,
  TableBody,
  TableTemplateCell,
} from 'components/common/table'
import { Tooltip } from 'components/common/tooltip'
import { FilterSelect, FilterSearch } from 'components/filter'
import { Header, HeaderTitle, HeaderActions, ActionsGroup } from 'components/styled/blocs'
import { Title, Subtitle } from 'components/styled/text'

import { generateUrl } from 'com.batch.common/router'

import { UserForm } from './user-form'
import { UserRow } from './user-row'
import { getAllowedAppsCountOnCompanyForUser } from './utils'

import {
  type AppRecord,
  type CompanyRecord,
  type State,
  type fetchingState,
} from 'com.batch.redux/_records'
import { fetchApps } from 'com.batch.redux/app.action'
import {
  listUsersByCompany,
  deleteUser,
  updateUserPermissions,
  inviteUser,
  resendInvite,
} from 'com.batch.redux/user'
import { UserFactory, type UserRecord } from 'com.batch.redux/user.records'
import { currentUserSelector, usersForCompanySelector } from 'com.batch.redux/user.selector'

import { NoResultWrapper } from 'com.batch/shared/ui/component/no-result-wrapper'
import { useConfirmWithMfa } from 'com.batch/shared/ui/hooks/use-confirm-with-mfa'
import { STATUS } from 'constants/common'

type OwnProps = {
  inviteOpened: boolean,
}
type StateProps = {
  company: CompanyRecord,
  apps: List<AppRecord>,
  users: Map<number, UserRecord>,
  currentUser: UserRecord,
  appsLoading: boolean,
  userLoadingState: fetchingState,
}
type DispatchProps = {
  fetchApps: typeof fetchApps,
  listUsersByCompany: typeof listUsersByCompany,
  updateUserPermissions: typeof updateUserPermissions,
  inviteUser: typeof inviteUser,
  resendInvite: typeof resendInvite,
  deleteUser: typeof deleteUser,
}
type TeamProps = { ...OwnProps, ...DispatchProps, ...StateProps, ... }

type userKind = 'all' | 'invite' | 'active' | 'none'
type userKindOption = { filter: userKind, label: string }
const userKindOptions: List<userKindOption> = new Immutable.List()
  .push({ filter: 'all', label: 'All users' })
  .push({ filter: 'active', label: 'Active urs' })
  .push({ filter: 'invite', label: 'Invited users' })

const TRACKING_CONTEXT = {
  eventLocation: 'team',
  searchEventCode: 'SEARCH_USERS',
  pagerEventCode: 'unset',
}

const coerceOrder = (value: ?string) => (value === 'asc' || value === 'dsc' ? value : 'asc')
const coerceField = (value: ?string) => (value === 'auth' || value === 'apps' ? value : 'email')
const coerceUserKind = (value: ?string): userKind =>
  value === 'invite' || value === 'active' ? value : 'all'
const nbPerPage = 8

const optToString = (opt: ?userKindOption) => opt?.label ?? ''

const TeamRaw = ({
  appsLoading,
  apps,
  inviteOpened = false,
  company,
  fetchApps,
  currentUser,
  inviteUser,
  users,
  updateUserPermissions,
  listUsersByCompany,
  resendInvite,
  deleteUser,
  userLoadingState,
}: TeamProps): React.Node => {
  React.useEffect(() => {
    listUsersByCompany(company)
    fetchApps(company.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.id, listUsersByCompany, fetchApps])

  const confirm = useConfirmWithMfa()
  const navigate = useNavigate()
  const query = useQuery()
  const { userId } = useParams()
  const editedUserId = React.useMemo(() => parseInt(userId, 10), [userId])
  const isUsersLoading = userLoadingState === STATUS.INIT || userLoadingState === STATUS.LOADING

  const canUseAppRestriction =
    (company.planFeaturesCode.has('app-user-restriction') ||
      company.additionalFeaturesCode.has('app-user-restriction')) &&
    !company.disabledFeaturesCode.has('app-user-restriction')

  const editing = typeof editedUserId === 'number' && !!users.get(editedUserId, false)

  const editedUser = inviteOpened
    ? UserFactory()
    : editedUserId
      ? users.get(editedUserId, UserFactory())
      : UserFactory()

  const appsCount = apps.size
  const remainingSeats = Math.max(0, company.seats - users.size)
  const invitesCount = users.filter(u => !u.onboardingStep.has('password')).size

  // current query string param, with defaults
  const qSortBy = coerceField(query.get('sortBy'))
  const qKind = coerceUserKind(query.get('u'))
  const qSortOrder = coerceOrder(query.get('sortOrder'))
  const qQuery: string = query.get('q') || ''
  const qPage = query.get('page') ? parseInt(query.get('page')) : 1

  const queryParams = React.useMemo(
    () => ({
      query: qQuery,
      sortBy: qSortBy,
      page: qPage,
      sortOrder: qSortOrder,
    }),
    [qPage, qQuery, qSortBy, qSortOrder]
  )
  // build query string based on partial params & current query string
  const buildSearch = React.useCallback(
    ({
      q,
      page,
      sortBy,
      sortOrder,
      userKind,
    }: {
      q?: string,
      page?: number,
      sortBy?: 'auth' | 'apps' | 'email',
      sortOrder?: 'dsc' | 'asc',
      userKind?: userKind,
      ...
    }) => {
      let cleanQ = typeof q === 'string' ? q : qQuery
      let cleanKind = typeof userKind === 'string' ? userKind : qKind
      let cleanPage = typeof page === 'number' ? page : qPage
      let cleanSortBy = typeof sortBy === 'string' ? sortBy : qSortBy
      let cleanSortOrder: 'dsc' | 'asc' = typeof sortOrder === 'string' ? sortOrder : qSortOrder
      return `q=${encodeURIComponent(
        cleanQ
      )}&page=${cleanPage}&sortBy=${cleanSortBy}&sortOrder=${cleanSortOrder}&u=${cleanKind}`
    },
    [qKind, qPage, qQuery, qSortBy, qSortOrder]
  )

  // filters users with q=?
  const filteredUsers = React.useMemo(
    () =>
      users.filter(
        user =>
          (qQuery === '' ||
            user.email.toLowerCase().indexOf(qQuery.toLowerCase()) !== -1 ||
            user.firstName.toLowerCase().indexOf(qQuery.toLowerCase()) !== -1 ||
            user.lastName.toLowerCase().indexOf(qQuery.toLowerCase()) !== -1) &&
          (qKind === 'all' ||
            (user.isInvite && qKind === 'invite') ||
            (!user.isInvite && qKind === 'active'))
      ),
    [qKind, qQuery, users]
  )

  // sort filtered users
  const sortedUsers = filteredUsers.toList().sort((a, b) => {
    const dir = qSortOrder === 'asc' ? 1 : -1
    switch (qSortBy) {
      case 'auth':
        return (a.securedBy2FA ? 1 : 0) > (b.securedBy2FA ? 1 : 0) ? dir : -dir
      case 'email':
        return a.email.toLowerCase() > b.email.toLowerCase() ? dir : -dir
      default: {
        const aCount = getAllowedAppsCountOnCompanyForUser({ user: a, company, appsCount })
        const bCount = getAllowedAppsCountOnCompanyForUser({ user: b, company, appsCount })
        return aCount > bCount ? dir : -dir
      }
    }
  })

  // slice a page of the sorted users
  const pagedUsers = React.useMemo(
    () => sortedUsers.slice((qPage - 1) * nbPerPage, qPage * nbPerPage),
    [qPage, sortedUsers]
  )

  // callbacks

  // confirmation dialog before user deletion
  const onDelete = React.useCallback(
    (user: UserRecord) => () =>
      confirm({
        title: 'Remove an user from your company',
        sensitive: true,
        message: (
          <React.Fragment>
            <p>
              The account{' '}
              <ConfirmHighlight>
                {user.firstName && user.lastName
                  ? `${user.firstName || ''} ${user.lastName || ''}`
                  : user.email || 'him'}
              </ConfirmHighlight>{' '}
              will lose its access to the dashboard.
            </p>
            <p>This action is definitive.</p>
          </React.Fragment>
        ),
        confirm: 'Yes, remove',
      }).then(
        () => deleteUser(company, user),
        () => {}
      ),
    [company, confirm, deleteUser]
  )

  const [filter, setFilter] = React.useState<userKind>('none')
  const selectedValue = React.useMemo(
    () => userKindOptions.find(opt => opt.filter === filter),
    [filter]
  )

  const updateSort = React.useCallback(
    (field: string) => {
      navigate({
        search: buildSearch({
          sortOrder: qSortOrder === 'asc' && qSortBy === field ? 'dsc' : 'asc',
          sortBy: field === 'auth' ? 'auth' : field === 'apps' ? 'apps' : 'email',
        }),
      })
    },
    [buildSearch, navigate, qSortBy, qSortOrder]
  )
  const onSortByMail = useSingleParamCallback(updateSort, 'email')
  const onSortByAuth = useSingleParamCallback(updateSort, 'auth')
  const onSortByApps = useSingleParamCallback(updateSort, 'apps')
  const onSave = React.useCallback(
    (user: UserRecord) =>
      user.id ? updateUserPermissions(company, user) : inviteUser({ user, company }),
    [company, inviteUser, updateUserPermissions]
  )
  const onModalClose = React.useCallback(
    () => navigate(generateUrl('user_list_by_company', { ...queryParams, companyId: company.id })),
    [navigate, queryParams, company.id]
  )
  const sortConfig = React.useMemo(
    () => ({ sortBy: query.get('sortBy') ?? 'email', sortOrder: qSortOrder }),
    [qSortOrder, query]
  )

  const modalOpened = React.useMemo(() => editing || inviteOpened, [editing, inviteOpened])
  const onSelectPage = React.useCallback(
    page => navigate({ search: buildSearch({ page }) }),
    [buildSearch, navigate]
  )
  const onSearchChange = React.useCallback(
    q => navigate({ search: buildSearch({ q, page: 1 }) }),
    [buildSearch, navigate]
  )

  const optionFormatter = React.useCallback(
    (opt, { context }) => {
      if (context === 'value') return opt.label
      switch (opt.filter) {
        case 'invite':
          return `${opt.label} (${users.filter(u => u.isInvite).size})`
        case 'active':
          return `${opt.label} (${users.filter(u => !u.isInvite).size})`
        default:
          return `${opt.label} (${users.size})`
      }
    },
    [users]
  )
  const onUserKindChange = React.useCallback(
    opt => {
      const filter = opt?.filter ?? 'none'
      trackEvent('FILTER_USERS', {
        location: TRACKING_CONTEXT.eventLocation,
        filter_type: filter,
      })
      navigate({
        search: buildSearch({ page: 1, userKind: filter }),
      })
      setFilter(filter)
    },
    [buildSearch, navigate]
  )
  const onResend = React.useCallback(
    (user: UserRecord) => () => resendInvite({ company, user }),
    [company, resendInvite]
  )
  return (
    <div style={{ padding: '22px 32px 22px 32px' }}>
      <TrackingContext.Provider value={TRACKING_CONTEXT}>
        <ThemeProvider theme={{ kind: 'filter', isLoading: isUsersLoading }}>
          <Header>
            <HeaderTitle>
              <Title>Manage team</Title>
              <Subtitle>
                {users.size} user{users.size > 1 && 's'}
                {invitesCount > 0 && (
                  <React.Fragment>
                    &nbsp;(including {invitesCount} invite{invitesCount > 1 && 's'})
                  </React.Fragment>
                )}
                &nbsp;out of {company.seats} seat{company.seats > 1 && 's'}
              </Subtitle>
            </HeaderTitle>
            <HeaderActions>
              <ActionsGroup>
                <div style={{ marginRight: 8 }}>
                  <FilterSearch
                    value={query.get('q') || ''}
                    onChange={onSearchChange}
                    placeholder="Search users"
                  />
                </div>
                <FilterSelect
                  isSearchable={false}
                  options={userKindOptions}
                  value={selectedValue ? selectedValue : null}
                  optionToString={optToString}
                  optionFormatter={optionFormatter}
                  onChange={onUserKindChange}
                  expandable={true}
                />
              </ActionsGroup>
              <ActionsGroup>
                <Tooltip
                  placement="bottom"
                  tooltip={
                    remainingSeats === 0
                      ? "You don't have any remaining seats. Contact our sales team to add more seats"
                      : `You have ${remainingSeats} seat${remainingSeats > 1 ? 's' : ''} remaining.`
                  }
                >
                  <div>
                    <ButtonNavLink
                      kind="primary"
                      intent="action"
                      disabled={remainingSeats < 1 || (isUsersLoading && !modalOpened)}
                      to={generateUrl('user_users_new_invite', {
                        ...queryParams,
                        companyId: company.id,
                      })}
                    >
                      Invite user
                    </ButtonNavLink>
                  </div>
                </Tooltip>
              </ActionsGroup>
            </HeaderActions>
          </Header>
          <Table
            template={`2fr 150px repeat(${canUseAppRestriction ? 2 : 1}, 1fr) 99px`}
            rowHeight={52}
          >
            <TableHeader>
              <TableCellOrder
                sort={sortConfig.sortBy === 'email' && sortConfig.sortOrder}
                onClick={onSortByMail}
              >
                User
              </TableCellOrder>
              <TableCellOrder
                sort={sortConfig.sortBy === 'auth' && sortConfig.sortOrder}
                onClick={onSortByAuth}
              >
                Authentication
              </TableCellOrder>
              <TableCellHeader>Permissions</TableCellHeader>
              {canUseAppRestriction && (
                <TableCellOrder
                  sort={sortConfig.sortBy === 'apps' && sortConfig.sortOrder}
                  onClick={onSortByApps}
                >
                  Apps
                </TableCellOrder>
              )}
              <div />
            </TableHeader>
            <TableBody emptyTemplate={<TableEmptyTemplate />}>
              <NoResultWrapper
                isEmpty={pagedUsers.size === 0 && !isUsersLoading}
                entityName="users"
              >
                {pagedUsers.map(u => (
                  <UserRow
                    key={u.id}
                    user={u}
                    appsLoading={appsLoading && !modalOpened}
                    canUseAppRestriction={canUseAppRestriction}
                    editUri={generateUrl('user_show', {
                      ...queryParams,
                      companyId: company.id,
                      userId: u.id,
                    })}
                    appsCount={apps.size}
                    company={company}
                    onResend={onResend(u)}
                    isSelf={!!u.id && u === currentUser}
                    onDelete={onDelete(u)}
                  />
                ))}
              </NoResultWrapper>
            </TableBody>
            {(editing || inviteOpened) && (
              <UserForm
                apps={apps}
                appsLoading={appsLoading}
                type="edit"
                user={editedUser}
                canUseAppRestriction={canUseAppRestriction}
                company={company}
                close={onModalClose}
                onSave={onSave}
              />
            )}
            {sortedUsers.size > nbPerPage && (
              <TableFooter>
                <Pager
                  total={sortedUsers.size}
                  nbPerPage={nbPerPage}
                  page={qPage}
                  selectPage={onSelectPage}
                />
              </TableFooter>
            )}
          </Table>
        </ThemeProvider>
      </TrackingContext.Provider>
    </div>
  )
}

const mapStateToProps = (state: State): StateProps => {
  return {
    company: state.company,
    apps: state.app.entities,
    appsLoading: state.app.loading,
    users: usersForCompanySelector(state),
    currentUser: currentUserSelector(state),
    userLoadingState: state.user.loadingState,
  }
}

const TableEmptyTemplate = () => {
  return (
    <React.Fragment>
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" align="end" />
      <TableTemplateCell template="1fr" />
    </React.Fragment>
  )
}

export const Team = (connect<TeamProps, OwnProps, _, _, _, _>(mapStateToProps, {
  listUsersByCompany,
  fetchApps,
  updateUserPermissions,
  inviteUser,
  resendInvite,
  deleteUser, // $FlowExpectedError we need to convert this to a func component so we don't have this madness
})(TeamRaw): React.AbstractComponent<OwnConfig>)

// @flow

import { type OptionsGeneric, type StrictModifiers } from '@popperjs/core'
import * as React from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { type RegionRecord, type LanguageRecord } from 'com.batch.redux/_records'
import { regions, languages } from 'com.batch.redux/campaign.selector'

export type CommonSelectProps<T> = {
  autoFocus?: boolean,
  forceOpen?: boolean,
  id?: string,
  isClearable?: boolean,
  isDisabled?: boolean,
  onFocus?: () => void,
  onBlur?: () => void,
  menuOffset?: number,
  optionFormatter?: (T, { context: 'value' | 'menu' }) => React.Node,
  optionMenuHeight?: number,
  optionMenuShownCount?: number,
  optionMenuStyle?: { [string]: string | number, ... },
  menuStyle?: { [string]: string | number, ... },
  optionToString: (?T) => string,
  placeholder?: string,
  noResultsNode?: React.Node | string,
  noOptionText?: string,
  style?: { [string]: string | number, ... },
  minWidth?: number,
  $noShadow?: boolean,
  'data-testid'?: string,
  ariaLabel?: string,
}

export const selectBaseCountryProps = {
  options: regions,
  optionToString: (region: ?RegionRecord): string => region?.label ?? '',
}
export const selectBaseLanguageProps = {
  options: languages,
  optionToString: (language: ?LanguageRecord): string => language?.label ?? '',
}
export const selectPopperConfig: $Shape<OptionsGeneric<StrictModifiers>> = {
  modifiers: [
    { name: 'applyStyles' },
    { name: 'eventListeners', enabled: false },
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['top-start'],
      },
    },
    {
      name: 'offset',
      options: {
        offset: [0, 6],
      },
    },
  ],
  placement: 'bottom-start',
  strategy: 'fixed',
}

/*
  really fake / partial definition, so flow screems at us if we forgot one of OUR props
*/
export type SelectMenuPropsType = {
  innerRef: React.Ref<*>,
  style: { [string]: string | number },
  'aria-labelledby': string,
  id: string,
}

export const RemovableValueButton: React.AbstractComponent<{
  children: React.Node,
  ...
}> = React.forwardRef(
  (
    {
      children,
      ...rest
    }: {
      children: React.Node,
      ...
    },
    ref
  ): React.Node => {
    return (
      <Button {...rest} ref={ref} addOn="prefix" kind="discreet" addOnGap={4}>
        {children}
        <Icon icon="remove" size={14} />
      </Button>
    )
  }
)

// @flow

import { scaleBand } from 'd3-scale'
import { type Dayjs } from 'dayjs'
import * as React from 'react'

import { TimelineItem } from './timeline-item'
import { SvgTimeline } from './timeline.styles'

import { type FullRangeBarChartData } from 'com.batch/shared/infra/types/chart-data'

type Props = {
  isLoading: boolean,
  isEmpty: boolean,
  height?: number,
  hideDisabledLabel?: boolean,
  hoveredDate: ?Dayjs,
  fullRangeBarChartData: Array<FullRangeBarChartData>,
  dateFormat: string,
  numberLabels?: number,
  setHoveredDate: (date: Dayjs | null) => void,
  width: number,
  xScale: typeof scaleBand,
  renameDates?: Array<{ date: Dayjs, name: string }>,
  centeredEnds?: boolean,
}

export const Timeline = ({
  numberLabels = 5,
  width,
  height = 36,
  xScale,
  fullRangeBarChartData = [],
  dateFormat,
  hoveredDate,
  setHoveredDate,
  hideDisabledLabel = false,
  isLoading,
  isEmpty,
  renameDates,
  centeredEnds = false,
}: Props): React.Node => {
  const ref = React.useRef<any>(null)
  const [firstLabelWidth, setFirstLabelWidth] = React.useState(0)
  const [lastLabelWidth, setLastLabelWidth] = React.useState(0)

  const shownLabels = React.useMemo(
    () => fullRangeBarChartData.filter(f => (hideDisabledLabel ? !f.isDisabled : true)),
    [fullRangeBarChartData, hideDisabledLabel]
  )
  const bandWidth = React.useMemo(() => Math.round(xScale.bandwidth()), [xScale])

  const initialLabels = React.useMemo(() => {
    const result = []

    if (shownLabels.length > 0) result.push(shownLabels[0])
    if (shownLabels.length > 1) result.push(shownLabels[shownLabels.length - 1])

    return result
  }, [shownLabels])

  const displayedLabels = React.useMemo(() => {
    const result = []

    const avalaibleSpace = width - firstLabelWidth - lastLabelWidth
    const nbParts = numberLabels - 1
    const interval = Math.round(avalaibleSpace / nbParts)

    let nextOne = firstLabelWidth + interval

    shownLabels.forEach((l, i) => {
      const start = bandWidth * i
      const end = start + bandWidth

      if (end > nextOne && end < width - lastLabelWidth && result.length < numberLabels - 2) {
        result.push(l)

        let middle = (end - start) / 2 + start
        nextOne = Math.round(middle + interval)
      }
    })

    const initialLabelsDates = initialLabels.map(f => f.date.format('YYYYMMDD'))
    // Remove initial labels from the result
    return result.filter(r => !initialLabelsDates.includes(r.date.format('YYYYMMDD')))
  }, [shownLabels, firstLabelWidth, bandWidth, lastLabelWidth, width, numberLabels, initialLabels])

  const fetchFirstLabelWidth = React.useCallback(
    width => {
      setFirstLabelWidth(Math.round(bandWidth > width ? bandWidth : width))
    },
    [bandWidth]
  )
  const fetchLastLabelWidth = React.useCallback(
    width => setLastLabelWidth(Math.round(bandWidth > width ? bandWidth : width)),
    [bandWidth]
  )

  const isFirst = React.useCallback(
    (date: Dayjs) => initialLabels.findIndex(f => f.date === date) === 0 && !centeredEnds,
    [initialLabels, centeredEnds]
  )
  const isLast = React.useCallback(
    (date: Dayjs) =>
      initialLabels.findIndex(f => f.date === date) === initialLabels.length - 1 && !centeredEnds,
    [initialLabels, centeredEnds]
  )

  const onBarHover = React.useCallback(
    (date: Dayjs | null) => () => setHoveredDate && setHoveredDate(date),
    [setHoveredDate]
  )

  const findRename = React.useCallback(
    date => renameDates?.find(f => f.date.utc().format() === date.utc().format())?.name ?? null,
    [renameDates]
  )

  if (width === 0) return
  return (
    <SvgTimeline width={width} height={36}>
      <defs>
        <linearGradient id="timeline-item-gradient-start">
          <stop className="stop1" offset="0%" />
          <stop className="stop2" offset="30%" />
          <stop className="stop3" offset="70%" />
          <stop className="stop4" offset="100%" />
        </linearGradient>
      </defs>

      <g ref={ref}>
        {[...initialLabels, ...displayedLabels].map((label, i) => {
          return (
            <TimelineItem
              key={i}
              isFirst={isFirst(label.date)}
              isLast={isLast(label.date)}
              timelineHeight={height}
              xScale={xScale}
              label={label.date.format(dateFormat)}
              fetchFirstLabelWidth={i === 0 ? fetchFirstLabelWidth : null}
              fetchLastLabelWidth={i === displayedLabels.length - 1 ? fetchLastLabelWidth : null}
              isLoading={isLoading}
              isEmpty={isEmpty}
              rename={findRename(label.date)}
            />
          )
        })}
      </g>

      {hoveredDate && !isLoading && !isEmpty && (
        <TimelineItem
          label={hoveredDate.format(dateFormat)}
          isHoveredLabel={true}
          isFirst={isFirst(hoveredDate)}
          isLast={isLast(hoveredDate)}
          xScale={xScale}
          timelineHeight={height}
          rename={findRename(hoveredDate)}
        />
      )}

      <g>
        {shownLabels
          .filter(f => !f.isDisabled)
          .map((label, i) => (
            <rect
              key={i}
              x={xScale(label.date.format(dateFormat))}
              y={0}
              width={xScale.bandwidth()}
              height={height}
              fill="transparent"
              onMouseEnter={onBarHover(label.date)}
              onMouseLeave={onBarHover(null)}
            />
          ))}
      </g>
    </SvgTimeline>
  )
}

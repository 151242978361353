// @flow
import Immutable, { type List, type Set } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Content } from 'components/styled/blocs'

import { ChannelsAnalytics } from './channels-analytics'

import { Filters } from '../filters/filters'
import { StepsAnalytics } from '../steps-analytics/steps-analytics'
import { globallyConfiguredLanguagesSelector } from 'com.batch/message/store/message.selector'
import { orchestrationStateSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'
import { OrchestrationAnalyticsDateRangeFactory } from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'

import {
  type OrchestrationStatsByStepRecord,
  OrchestrationStepFactory,
  type OrchestrationStepRecord,
  StepStatsFactory,
} from 'com.batch/orchestration-analytics/models/orchestration-stats-by-step.record'
import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'
import { useUpdateFiltersUrl } from 'com.batch/orchestration-analytics/ui/hooks/use-update-filters-url'
import { updateAnalyticsDateRange } from 'com.batch/orchestration-analytics/usecases/analytics-filters'
import { journeyTreeSelector } from 'com.batch/orchestration-journey/models/journey.selectors'
import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'

export const OrchestrationAnalytics = (): React.Node => {
  const { token }: { token: string, ... } = useParams()
  const dispatch = useDispatch()
  const schedulingType = useGetSchedulingTypeFromUrl()
  const isAutomation = React.useMemo(() => schedulingType === 'automations', [schedulingType])
  const { channels: channelsFromOrchestration, campaign } = useSelector(orchestrationStateSelector)
  const { nodesMap } = useSelector(journeyTreeSelector)
  const analyticsMap = useSelector(orchestrationAnalyticsMapSelector)
  const { filters, dateRange } = useGetAnalyticsFilters()
  const configuredLanguages = useSelector(globallyConfiguredLanguagesSelector)
  const statsByStep: ?OrchestrationStatsByStepRecord = React.useMemo(
    () => analyticsMap.getIn([token, 'steps']),
    [analyticsMap, token]
  )

  useUpdateFiltersUrl({ filters, dateRange, isAutomation })

  const { allSteps, existingSteps } = React.useMemo(() => {
    const existingSteps: List<OrchestrationStepRecord> = nodesMap.reduce((acc, node) => {
      if (node.type !== 'MESSAGE') return acc
      const step =
        statsByStep?.find(step => step.step === node.id) ??
        OrchestrationStepFactory({
          step: node.id,
          channel: node.channel,
          stats: StepStatsFactory(),
        })
      return acc.push(step)
    }, new Immutable.List())
    const deletedSteps: List<OrchestrationStepRecord> =
      // On activera l'affichage des steps supprimées même temps que les filtres
      statsByStep ? statsByStep.filter(step => !nodesMap.has(step.step)) : new Immutable.List()
    return {
      allSteps: new Immutable.List().concat(existingSteps, deletedSteps),
      existingSteps,
    }
  }, [nodesMap, statsByStep])

  const channelsFromSteps: Set<ChannelUntilCleanup> = React.useMemo(() => {
    const stepFilter = filters.get('step')
    return allSteps
      .filter(step => (!stepFilter ? true : stepFilter.includes(step.step)))
      .reduce((acc, step) => (step.channel ? acc.add(step.channel) : acc), new Immutable.Set())
  }, [allSteps, filters])
  const channels = React.useMemo(
    () => (campaign.sendType === 'trigger' ? channelsFromSteps : channelsFromOrchestration),
    [campaign.sendType, channelsFromOrchestration, channelsFromSteps]
  )

  const { showStepReport, showStepFilter } = React.useMemo(
    () => ({
      showStepReport: allSteps.size > 1,
      showStepFilter: existingSteps.size > 1,
    }),
    [allSteps, existingSteps]
  )
  const hideFilterBar = React.useMemo(
    () => !showStepFilter && configuredLanguages.size < 2 && schedulingType === 'campaigns',
    [showStepFilter, configuredLanguages, schedulingType]
  )

  // Init default date range
  React.useEffect(() => {
    if (token && isAutomation && !dateRange) {
      // Si pas de filtre de date dans l'url alors on applique le default date range de 7j
      dispatch(
        updateAnalyticsDateRange({ token, dateRange: OrchestrationAnalyticsDateRangeFactory() })
      )
    }

    // On souhaite initialiser le default dateRange uniquement au lancement de la page. On ne souhaite pas le reinitialiser à chaque changement de filtre.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAutomation, token])

  return (
    <React.Fragment>
      {!hideFilterBar && <Filters showStepFilter={showStepFilter} />}
      <Content style={{ overflow: 'visible', padding: '40px 26px 0' }}>
        {showStepReport && <StepsAnalytics steps={allSteps} />}
        <ChannelsAnalytics channels={channels} />
      </Content>
    </React.Fragment>
  )
}

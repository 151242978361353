// @flow
import styled, { type StyledComponent } from 'styled-components'

import { BoxBody, BoxHeader } from 'components/common/box'
import { colors } from 'components/styled/tokens'

import { ProfilebaseBox } from 'com.batch/profilebase/ui/pages/profilebase.syles'

export const ProfilesMetricsBox: StyledComponent<{}, {}, typeof ProfilebaseBox> = styled(
  ProfilebaseBox
)`
  ${BoxHeader} {
    height: auto;
    padding: 16px 14px 14px 14px;

    i {
      font-size: 0.9em;
      margin-right: 4px;
    }
  }

  ${BoxBody} {
    display: flex;
    align-items: flex-end;
    padding: 0 20px 16px 20px;
    gap: 30px;
  }
`

export const HelpContainer: StyledComponent<{}, {}, HTMLDivElement> = styled.div`
  width: 233px;
  text-align: left;
  font-size: 14px;
  color: ${colors.textContrastLight};

  & > span {
    display: block;
    color: ${colors.textContrast};
    font-weight: 500;
    margin-bottom: 4px;
  }
`

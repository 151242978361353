// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { type StateToggler } from 'components/_hooks'
import {
  Box,
  BoxHeader,
  HeaderBoxTitle,
  BoxBody,
  BoxFooter,
  HeaderBoxActions,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Input } from 'components/form'
import { Radio } from 'components/form/fields/radio'

import { saveTestIdsInLocalStorage } from '../usecases/save-test-ids'
import { sendTestPush, type SendTestRecipientIdKind } from '../usecases/send-test-push'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

export const savedRecipientsIdsKey = 'sendTestPushRecipientIds'

type PopinSendTestPushProps = {
  messageId: string,
  togglePopin: StateToggler,
}

export const PopinSendTestPush = ({
  messageId,
  togglePopin,
}: PopinSendTestPushProps): React.Node => {
  const dispatch = useDispatch()
  const { id: projectId } = useSelector(currentProjectSelector)
  const scopedSavedTestIdsKey = React.useMemo(
    () => `${projectId}-${savedRecipientsIdsKey}`,
    [projectId]
  )

  const [isLoading, setIsLoading] = React.useState(false)
  const [value, setValue] = React.useState('')
  const [sendTestIdKind, setSendTestIdKind] = React.useState<SendTestRecipientIdKind>('customID')

  const onSendTestIdKindChange = React.useCallback(
    (kind: SendTestRecipientIdKind) => () => {
      setSendTestIdKind(kind)
    },
    [setSendTestIdKind]
  )

  const onTestIdValueChange = React.useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      setValue(e.currentTarget.value)
    },
    [setValue]
  )

  const onSubmit = React.useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      setIsLoading(true)
      e.preventDefault()
      dispatch(
        sendTestPush({
          messageId,
          recipientId: {
            kind: sendTestIdKind,
            id: value,
          },
        })
      )
        .then(() => {
          saveTestIdsInLocalStorage({
            key: scopedSavedTestIdsKey,
            value: {
              kind: sendTestIdKind,
              id: value,
            },
          })
          togglePopin.close()
        })
        .catch(() => {})
        .finally(() => {
          setValue('')
          setIsLoading(false)
        })
    },
    [dispatch, messageId, value, sendTestIdKind, scopedSavedTestIdsKey, togglePopin]
  )

  return (
    <Popin opened={togglePopin.value} close={togglePopin.close} style={{ width: 546 }}>
      <form onSubmit={onSubmit}>
        <Box>
          <BoxHeader>
            <HeaderBoxTitle title="Send a test" />
            <HeaderBoxActions large>
              <Button type="button" onClick={togglePopin.close}>
                <Icon icon="close" />
              </Button>
            </HeaderBoxActions>
          </BoxHeader>
          <BoxBody
            style={{
              padding: 20,
            }}
          >
            <div role="radiogroup" aria-label="Target Custom or Installation ID">
              <Radio
                onChange={onSendTestIdKindChange('customID')}
                label="Custom ID"
                checked={sendTestIdKind === 'customID'}
                style={{ marginRight: '12px' }}
              />
              <Radio
                onChange={onSendTestIdKindChange('installID')}
                label="Installation ID"
                checked={sendTestIdKind === 'installID'}
                style={{ marginRight: '12px' }}
              />
            </div>
            <Input
              id="code"
              style={{ margin: '12px 0px 0px' }}
              placeholder={
                sendTestIdKind === 'customID' ? 'Enter a custom ID' : 'Enter an installation ID'
              }
              value={value}
              onChange={onTestIdValueChange}
            />
          </BoxBody>
          <BoxFooter isEditable>
            <Button type="button" kind="inline" onClick={togglePopin.close}>
              Cancel
            </Button>
            <Button
              kind="primary"
              intent="action"
              disabled={value.length < 2}
              isLoading={isLoading}
            >
              Send test
            </Button>
          </BoxFooter>
        </Box>
      </form>
    </Popin>
  )
}

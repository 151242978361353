// @flow
import * as React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import { type StateToggler } from 'components/_hooks'
import { useIntercomVerticalPadding } from 'components/_hooks/use-intercom-vertical-padding'
import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  HeaderBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import {
  getAreRequiredEmailFieldsFilledSelector,
  getEmailContentLoadingStateSelector,
} from 'com.batch/email/store/email-automation.selector'

import { SendTestEmailButton } from 'com.batch/email/ui/components/send-test-email-button'
import { MultilanguageToggle } from 'com.batch/message/ui/components/multilanguage-toggle'
import { STATUS } from 'constants/common'

type EmailEditorPopinProps = {
  popinState: StateToggler,
  children: React.Node,
  saveAction: () => void,
  cancelAction?: () => Promise<void> | void,
  sendTestEmailPopinState?: StateToggler,
  sendTestClickAddons?: () => void,
  messageId: string,
  templateId?: string,
}
export const EmailEditorPopin = ({
  popinState,
  children,
  saveAction,
  cancelAction,
  sendTestEmailPopinState,
  sendTestClickAddons,
  messageId,
  templateId,
}: EmailEditorPopinProps): React.Node => {
  const theme = useTheme()
  const getAreRequiredEmailFieldsFilled = useSelector(getAreRequiredEmailFieldsFilledSelector)
  const areRequiredEmailFieldsFilled = React.useMemo(
    () => getAreRequiredEmailFieldsFilled(messageId),
    [getAreRequiredEmailFieldsFilled, messageId]
  )
  const getLoadingState = useSelector(getEmailContentLoadingStateSelector)
  const loadingState = React.useMemo(() => getLoadingState(messageId), [getLoadingState, messageId])
  const isLoading = React.useMemo(() => loadingState === STATUS.LOADING, [loadingState])

  useIntercomVerticalPadding(56, 72)

  return (
    <Box style={{ height: '100vh' }} data-testid="email-editor-popin">
      <BoxHeader style={{ flexShrink: 0 }}>
        <HeaderBoxTitle title="Email message" />
        <HeaderBoxActions>
          <MultilanguageToggle messageId={messageId} channel="email" templateId={templateId} />
          <Button kind="inline" intent="neutral" onClick={cancelAction} type="button">
            <Icon icon="close" />
          </Button>
        </HeaderBoxActions>
      </BoxHeader>

      <BoxBody
        style={{
          height: 'calc(100vh - 56px * 2)',
        }}
      >
        {children}
      </BoxBody>

      <BoxFooter style={{ flexShrink: 0 }}>
        <div style={{ width: '100%', display: 'flex' }}>
          <Button onClick={cancelAction ?? popinState.close}>Cancel</Button>

          <div style={{ display: 'flex', marginLeft: 'auto', gap: 8 }}>
            {sendTestEmailPopinState && (
              <SendTestEmailButton
                messageId={messageId}
                hasMandatoryInfo={areRequiredEmailFieldsFilled}
                togglePopinSendTest={sendTestEmailPopinState}
                onClickAddons={sendTestClickAddons ?? undefined}
              ></SendTestEmailButton>
            )}

            <Button
              kind="secondary"
              onClick={saveAction}
              isLoading={isLoading}
              disabled={theme?.disabledMode}
              data-testid="save-email-popin-button"
            >
              Continue
            </Button>
          </div>
        </div>
      </BoxFooter>
    </Box>
  )
}

// @flow

import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

import { Button, ButtonNavLink } from 'components/common/button'
import {
  FullPageContent,
  FullPageTitle,
  FullPageSubtitle,
  FullPageFooter,
} from 'components/common/full-page'
import { Icon } from 'components/common/svg-icon'
import { Form, InputWrapper, Input } from 'components/form'

import { generateUrl } from 'com.batch.common/router'
import { validateUrl } from 'com.batch.common/utils'

import { AppFactory, type CompanyRecord, type AppRecord } from 'com.batch.redux/_records'
import { fetchAppStore } from 'com.batch.redux/app.api'

type StepUrlProps = {
  company: CompanyRecord,
  app: AppRecord,
  setApp: AppRecord => any,
  ...
}

export const StepUrlRaw = ({ company, app, setApp }: StepUrlProps): React.Node => {
  const isMobile = app.platform !== 'webpush'
  const [url, setUrl] = React.useState(isMobile ? '' : app.bundleId)
  const [fetching, setFetching] = React.useState(false)
  const [error, setError] = React.useState(false)
  const navigate = useNavigate()
  const backUrl = generateUrl('app_new', {
    companyId: company.id,
  })
  const nextUrl = generateUrl('app_new_kind', {
    companyId: company.id,
    kind: isMobile ? 'mobile' : 'web',
  })
  const onUrlChange = React.useCallback(evt => setUrl(evt.target.value), [setUrl])
  const onClickBack = React.useCallback(() => {
    setApp(
      app
        .set('bundleId', '')
        .set('icon', '')
        .set('name', '')
        .set('sdk', '')
        .set('platform', isMobile ? 'mobile' : 'webpush')
    )
    navigate(backUrl)
  }, [app, isMobile, navigate, backUrl, setApp])
  const onSubmit = React.useCallback(() => {
    if (!fetching && validateUrl(url)) {
      setFetching(true)
      setError(false)
      const ensureHttpsInUrl = url.indexOf('http') === 0 ? url : `https://${url}`
      const appOrUrl = isMobile
        ? ensureHttpsInUrl
        : AppFactory({ bundleId: url, platform: 'webpush' })
      fetchAppStore(appOrUrl).then(
        matchingApp => {
          setFetching(false)
          if (isMobile) {
            setApp(
              app
                .set('bundleId', matchingApp.bundleId)
                .set('name', matchingApp.name || '')
                .set('platform', matchingApp.platform)
                .set(
                  'sdk',
                  matchingApp.platform === 'android'
                    ? 'Android'
                    : matchingApp.platform === 'ios'
                      ? 'iOS'
                      : 'Windows'
                )
                .set('icon', matchingApp.icon)
            )
          } else {
            setApp(
              app
                .set('bundleId', url)
                .set('name', matchingApp.name || '')
                .set('platform', 'webpush')
                .set('sdk', 'web')
                .set('icon', matchingApp.icon)
            )
          }
          navigate(nextUrl)
        },
        err => {
          console.warn(err)
          if (!isMobile) {
            setApp(app.set('bundleId', url).set('sdk', 'web'))
            navigate(nextUrl)
          }
          setError(true)
          setFetching(false)
        }
      )
    } else {
      setError(true)
    }
  }, [app, isMobile, navigate, nextUrl, setApp, url, fetching])
  return (
    <Form onSubmit={onSubmit}>
      <FullPageContent>
        <Helmet>
          <title>Batch - Url of your {isMobile ? 'App' : 'Website'}</title>
        </Helmet>
        <FullPageTitle>{isMobile ? 'Application URL' : 'Website URL'}</FullPageTitle>
        {isMobile ? (
          <FullPageSubtitle>
            Provide your app URL if it is already available on the store.
          </FullPageSubtitle>
        ) : (
          <FullPageSubtitle>Please enter your website URL to start the setup.</FullPageSubtitle>
        )}
        <div>
          <InputWrapper
            label={isMobile ? 'Application store URL' : null}
            hint={isMobile ? 'Provide an App Store or Google Play URL' : null}
            feedback={
              error &&
              (isMobile
                ? 'Please enter a valid App Store or Google Play URL.'
                : 'Please enter a valid URL.')
            }
          >
            <Input
              type="url"
              value={url}
              placeholder={
                isMobile
                  ? 'https://apps.apple.com/... or https://play.google.com/store/...'
                  : 'http://www.website.url'
              }
              invalid={error}
              onChange={onUrlChange}
              aria-label={isMobile ? 'Application store URL' : 'URL'}
            />
          </InputWrapper>
        </div>
        <FullPageFooter>
          <Button
            style={{ marginRight: 46 }}
            kind="inline"
            type="button"
            tabIndex={1}
            onClick={onClickBack}
            addOn="prefix"
          >
            <Icon icon="arrow-long-left" />
            Back
          </Button>
          {isMobile && (
            <ButtonNavLink
              kind="inline"
              tabIndex={1}
              end
              style={{ marginRight: 12 }}
              to={generateUrl('app_new_kind', {
                companyId: company.id,
                kind: isMobile ? 'mobile' : 'web',
              })}
            >
              Skip
            </ButtonNavLink>
          )}
          <Button tabIndex={2} kind="primary" intent="action" isLoading={fetching} type="submit">
            Continue
          </Button>
        </FullPageFooter>
      </FullPageContent>
    </Form>
  )
}
export const StepUrl: React.AbstractComponent<StepUrlProps> = React.memo<StepUrlProps>(StepUrlRaw)

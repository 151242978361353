// @flow
import Immutable from 'immutable'

import { type HtmlEditorConfig } from '../../models/html-editor-config'
import { type EmailContent } from '../types/email.message.types'

import {
  type EmailMessageRecord,
  EmailMessageFactory,
  EmailContentFactory,
  EmailTemplatesFactory,
} from 'com.batch/message/models/message.records'
import { type DashboardConfigByMessageId } from 'com.batch/orchestration/infra/types/orchestration.types'
import { type EmailContentForCampaign } from 'com.batch/orchestration-campaign/infra/types/orchestration-campaign.types'
import { getMissingFields } from 'com.batch/orchestration-journey/usecases/check-incomplete-message-node'

export const parseEmail = (
  raw: EmailContent,
  config: ?DashboardConfigByMessageId
): {
  message: EmailMessageRecord,
  contentFullyEmpty: boolean,
  contentInvalid: boolean,
} => {
  let contentInvalid = false
  let contentFullyEmpty = false
  return {
    message: EmailMessageFactory({
      multilanguageEnabled: (raw.localizedEmails?.length ?? 0) > 1,
      localizedContent: Immutable.OrderedMap(
        (raw?.localizedEmails ?? []).map(({ language, region, message }) => {
          const lang =
            language && region ? `${language}-${region}` : language ? language : 'default'
          const htmlEditorConfig = config?.langConfigs?.[lang]?.htmlEditorConfig ?? null
          const senderIdAsInt = parseInt(message.from.senderIdentityId)
          const emailContent = EmailContentFactory({
            fromEmail: message.from.address.email,
            replyTo: message.replyTo ?? '',
            name: message.from.address.name,
            subject: message.subject,
            html: message.html,
            templates: EmailTemplatesFactory({
              subject: message.subject,
              html: message.html,
              replyTo: message.replyTo,
            }),
            htmlEditorConfig:
              htmlEditorConfig && htmlEditorConfig?.type
                ? // flow can't refine type here, hence the any
                  ((htmlEditorConfig: any): HtmlEditorConfig)
                : null,
            senderIdentityId: !isNaN(senderIdAsInt) ? senderIdAsInt : undefined,
          })
          const missing = getMissingFields(emailContent)
          if (missing.length === 5) {
            contentFullyEmpty = true
          }
          if (missing.length > 0) {
            contentInvalid = true
          }

          return [lang, emailContent]
        })
      ),
    }),
    contentInvalid,
    contentFullyEmpty,
  }
}

export const parseEmailForCampaign = (
  raw: EmailContentForCampaign,
  config: ?DashboardConfigByMessageId
): {
  message: EmailMessageRecord,
  contentFullyEmpty: boolean,
  contentInvalid: boolean,
} => {
  let contentInvalid = false
  let contentFullyEmpty = false
  return {
    message: EmailMessageFactory({
      multilanguageEnabled: raw.email.localizedEmails.length > 1,
      localizedContent: Immutable.OrderedMap(
        raw.email.localizedEmails.map(({ language, region, subject, html, from, replyTo }) => {
          const lang =
            language && region ? `${language}-${region}` : language ? language : 'default'
          const htmlEditorConfig = config?.langConfigs?.[lang]?.htmlEditorConfig ?? null
          const senderIdAsInt = parseInt(
            from ? from.senderIdentityId : raw.email.from?.senderIdentityId
          )
          const emailContent = EmailContentFactory({
            fromEmail: from ? from.address.email : raw.email.from?.address.email,
            replyTo: replyTo ? replyTo : raw.email.replyTo ?? '',
            name: from ? from.address.name : raw.email.from?.address.name,
            subject,
            html,
            templates: EmailTemplatesFactory({
              subject: subject,
              html: html,
              replyTo: replyTo ? replyTo : raw.email.replyTo ?? '',
            }),
            htmlEditorConfig:
              htmlEditorConfig && htmlEditorConfig?.type
                ? // flow can't refine type here, hence the any
                  ((htmlEditorConfig: any): HtmlEditorConfig)
                : null,
            senderIdentityId: !isNaN(senderIdAsInt) ? senderIdAsInt : undefined,
          })
          const missing = getMissingFields(emailContent)
          if (missing.length === 5) {
            contentFullyEmpty = true
          }
          if (missing.length > 0) {
            contentInvalid = true
          }

          return [lang, emailContent]
        })
      ),
    }),
    contentInvalid,
    contentFullyEmpty,
  }
}

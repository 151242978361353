// @flow
// ================= Libs
import * as React from 'react'
import { type Node as ReactNode } from 'react'
import { ThemeContext } from 'styled-components'

// ================= Components
import { Button } from 'components/common/button'
import { EmptyField } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'

// ================= Utils
import { randomSize } from 'com.batch.common/utils'

import { CopyInputContainer } from './copy.styles'

import { InputWrapper } from '../input-wrapper'

// ================= Types

type CopyFieldProps = {
  value: string,
  hideButton?: boolean,
  label?: ReactNode,
  isSensitive?: boolean,
  hint?: ReactNode,
  htmlFor?: string,
  currentColor?: boolean,
  ...
}

const Copy = ({
  value,
  isSensitive,
  hideButton = false,
  label,
  hint,
  htmlFor,
  currentColor,
}: CopyFieldProps): React.Node => {
  const [copied, setCopied] = React.useState(false)
  const [emptyFieldSize] = React.useState(`${randomSize(40, 70)}%`)

  const ref = React.useRef(null)
  const theme = React.useContext(ThemeContext)
  const isLoading = React.useMemo(() => !!theme && theme.isLoading, [theme])
  const isEmpty = React.useMemo(() => !!theme && theme.isEmpty, [theme])

  const select = React.useCallback(() => !!ref.current && ref.current.select(), [])

  const copy = React.useCallback(() => {
    select()
    try {
      const success = document.execCommand('copy')
      // $FlowFixMe dunno how to type this
      if (document.selection) {
        // $FlowFixMe dunno how to type this
        document.selection.empty()
      } else if (window.getSelection) {
        window.getSelection().removeAllRanges()
      }
      setCopied(success)
    } catch (err) {
      console.log('Oops, unable to copy')
    }
  }, [select])

  React.useEffect(() => {
    setTimeout(() => setCopied(false), 1800)
  })
  const onCopy = React.useCallback(() => {
    if (!isLoading && !isEmpty) copy()
  }, [copy, isEmpty, isLoading])
  return (
    <InputWrapper label={label} hint={hint} htmlFor={htmlFor}>
      <CopyInputContainer currentColor={currentColor}>
        {isLoading || isEmpty ? (
          <div style={{ width: '100%', display: 'flex', alignContent: 'center' }}>
            <EmptyField _width={emptyFieldSize} />
          </div>
        ) : (
          <input
            className={isSensitive ? 'fs-exclude' : ''}
            readOnly
            type="text"
            id={htmlFor}
            ref={ref}
            value={value}
            onClick={select}
          />
        )}

        <span data-testid="copiedTag" style={{ opacity: copied ? 1 : 0 }}>
          <Icon icon="check" style={{ marginRight: 4 }} /> copied
        </span>

        {!hideButton && (
          <div className="styled-input-addons styled-addon-suf" onClick={onCopy}>
            <Button
              type="button"
              kind="discreet"
              intent="action"
              style={{ height: 28 }}
              aria-label="Copy text"
            >
              <Icon icon="copy" />
            </Button>
          </div>
        )}
      </CopyInputContainer>
    </InputWrapper>
  )
}

export { Copy }

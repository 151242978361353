// @flow

import { type List } from 'immutable'
import * as React from 'react'
import { FixedSizeList } from 'react-window'

import { DropdownMenu, Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Checkbox } from 'components/form/fields/checkbox'

import { SelectNoOption, SelectOption } from './select.styles'

type SelectDropdownProps<T> = {
  getItemProps: ({ item: T, index: number, ... }) => { onClick: () => void, ... },
  highlightedIndex: number,
  innerRef: React.Ref<*>,
  isOpen: boolean,
  creatableOption?: ?T,
  isOptionSelected: T => boolean,
  inMenuSearchInput?: React.Node,
  optionFormatter?: (T, { context: 'value' | 'menu' }) => React.Node,
  optionMenuShownCount?: number,
  optionMenuHeight?: number,
  optionMenuStyle?: { [string]: string | number, ... },
  menuStyle?: { [string]: string | number, ... },
  options: List<T>,
  optionToString: T => string,
  setHighlightedIndex: number => void,
  showCheckbox?: boolean,
  noResultNode?: React.Node,
  style?: { [string]: string | number, ... },
  width: number,
  header?: React.Node,
  id: string,
  'aria-labelledby': string,
}

const MENU_SPACING = 4

export function SelectDropdown<T>({
  getItemProps,
  highlightedIndex,
  innerRef,
  isOpen,
  isOptionSelected,
  inMenuSearchInput,
  optionFormatter,
  creatableOption,
  optionMenuShownCount = 4,
  optionMenuHeight = 36,
  optionMenuStyle,
  menuStyle,
  options,
  optionToString,
  setHighlightedIndex,
  showCheckbox,
  noResultNode,
  width,
  header,
  id,
  'aria-labelledby': ariaLabelledBy,
}: SelectDropdownProps<T>): React.Node {
  /* 
    we need to compute 2 heights for this to work : 
    - the height of the windowed list (FixedSizedList) will be number options displayed * (optionMenuHeight + spacing)
    - the height of the dropdown will be the height of the windowed list + 
      - height of the search input if present
      - height of the loading indicator if present (removed)
      - height of the no results indicator if present
  */

  // we assume padding is 2px, might need to be dynamic
  const optionHeight = React.useMemo(() => optionMenuHeight + MENU_SPACING, [optionMenuHeight])
  // max height is
  const windowedListHeight = React.useMemo(() => {
    return Math.min(options.size, optionMenuShownCount) * optionHeight
  }, [options.size, optionMenuShownCount, optionHeight])
  const dropdownWillScroll = React.useMemo(
    () => options.size > optionMenuShownCount,
    [optionMenuShownCount, options.size]
  )
  const dropdownHeight = React.useMemo(() => {
    const searchInputHeight = inMenuSearchInput ? 36 : 0
    const noResultsHeight = noResultNode && windowedListHeight === 0 ? 76 : 0
    const explicitNewOption = creatableOption ? optionHeight : 0

    return (
      windowedListHeight +
      searchInputHeight +
      noResultsHeight +
      explicitNewOption +
      (dropdownWillScroll ? 2 : 6) +
      (header ? 37 : 0)
    )
  }, [
    inMenuSearchInput,
    noResultNode,
    windowedListHeight,
    creatableOption,
    optionHeight,
    dropdownWillScroll,
    header,
  ])

  const onDropdownMenuMouseLeave = React.useCallback(() => {
    setHighlightedIndex(-1)
  }, [setHighlightedIndex])

  const renderVirtualOption = React.useCallback(
    ({ style, index }) => {
      const item = options.get(index)
      return (
        <SelectOption style={style} key={index} isActive={isOptionSelected(item)}>
          <Button
            {...getItemProps({
              index,
              key: optionToString(item),
              style: { ...optionMenuStyle, height: optionMenuHeight },
              item,
            })}
            addOnGap={4}
            addOn={showCheckbox ? 'prefix' : undefined}
            kind="inline"
            isHover={highlightedIndex === index && !isOptionSelected(item)}
            isActive={isOptionSelected(item)}
          >
            {showCheckbox && (
              <Checkbox
                size={18}
                checked={isOptionSelected(item)}
                style={{ pointerEvents: 'none', marginTop: 2, marginRight: '-4px' }}
              />
            )}
            {optionFormatter ? optionFormatter(item, { context: 'menu' }) : optionToString(item)}
          </Button>
        </SelectOption>
      )
    },
    [
      getItemProps,
      highlightedIndex,
      isOptionSelected,
      optionFormatter,
      optionMenuHeight,
      optionMenuStyle,
      optionToString,
      options,
      showCheckbox,
    ]
  )

  return (
    <DropdownMenu
      alwaysInDom
      ref={innerRef}
      style={{
        ...menuStyle,
        padding: 0,
        borderRadius: '6px',
        height: options.size > 4 ? dropdownHeight + 26 : dropdownHeight,
        overflowY: 'hidden',
      }}
      forcedWidth={width}
      willScroll={dropdownWillScroll}
      onMouseLeave={onDropdownMenuMouseLeave}
      forcedHeight={options.size > 4 ? dropdownHeight + 26 : dropdownHeight}
      isOpen={
        isOpen &&
        (options.size > 0 ||
          Boolean(noResultNode) ||
          Boolean(inMenuSearchInput) ||
          Boolean(creatableOption))
      }
      id={id}
      aria-labelledby={ariaLabelledBy}
      role="listbox"
    >
      {inMenuSearchInput}
      {header}
      <FixedSizeList
        height={options.size > 4 ? windowedListHeight + 28 : windowedListHeight}
        width="100%"
        itemSize={optionHeight}
        itemCount={options.size}
        className="styled-windowed-list"
      >
        {renderVirtualOption}
      </FixedSizeList>
      {Boolean(noResultNode) && options.size === 0 && (
        <SelectNoOption>{noResultNode}</SelectNoOption>
      )}
      {!!creatableOption && (
        <div>
          <Button
            {...getItemProps({
              index: 0,
              style: {
                marginLeft: 4,
                height: optionMenuHeight,
                width: 'calc(100% - 8px)',
              },
              item: creatableOption,
            })}
            addOnGap={4}
            addOn="suffix"
            kind="inline"
            isHover={highlightedIndex === 0 && !isOptionSelected(creatableOption)}
            isActive={isOptionSelected(creatableOption)}
          >
            <span>
              <span style={{ opacity: 0.7 }}>Use&nbsp;</span>
              {optionToString(creatableOption)}
            </span>
            <Icon icon="add" />
          </Button>
        </div>
      )}
    </DropdownMenu>
  )
}

// @flow
import Immutable, { type List } from 'immutable'
import * as React from 'react'

import { FlexLine } from 'components/common/flexline'
import { Select } from 'components/form'
import { Input } from 'components/form/fields/input'

import { buildAgeFromInputValue } from 'com.batch.common/utils'

import { type AgeRecord, type intervalUnit } from 'com.batch.redux/_records'

type PeriodKeys = 's' | 'm' | 'h' | 'd'
type PeriodOption = { label: string, key: PeriodKeys, multiplier: number }

const PeriodOptions: List<PeriodOption> = new Immutable.List().push(
  { label: 'second', key: 's', multiplier: 1 },
  { label: 'minute', key: 'm', multiplier: 60 },
  { label: 'hour', key: 'h', multiplier: 60 * 60 },
  { label: 'day', key: 'd', multiplier: 60 * 60 * 24 }
)

type TimeIntervalInputProps = {
  age: AgeRecord,
  autoFocus?: boolean,
  min?: number,
  max?: number,
  placeholder?: string,
  invalid?: boolean,
  onBlur?: () => void,
  allowedUnits?: Array<intervalUnit>,
  onChange: (age: AgeRecord) => void,
  style?: any,
}

const unitToString = (o: ?PeriodOption) => o?.label ?? ''

const TimeIntervalInput = ({
  allowedUnits = ['m', 'h', 'd'],
  autoFocus = false,
  min = 0,
  max = Infinity,
  placeholder = '',
  onChange,
  invalid,
  age,
  onBlur,
  style,
}: TimeIntervalInputProps): React.Node => {
  const options = PeriodOptions.filter(o => allowedUnits.includes(o.key))

  const preventKeyPress = React.useCallback(
    evt => {
      const forbidden = ['.', ',', min < 0 ? '+' : '-']
      const isLetter = evt.key.length === 1 && evt.key.match(/[a-z]/i)
      if (forbidden.includes(evt.key) || isLetter) {
        evt.preventDefault()
      }
    },
    [min]
  )
  const onValueChange = React.useCallback(
    e => onChange(buildAgeFromInputValue(e.target.value, age.unit, min, max, allowedUnits, false)),
    [age.unit, allowedUnits, max, min, onChange]
  )
  const onUnitChange = React.useCallback(
    p => {
      if (p) onChange(buildAgeFromInputValue(age.inputValue, p.key, min, max, allowedUnits, false))
    },
    [age.inputValue, allowedUnits, max, min, onChange]
  )
  const unitFormatter = React.useCallback(
    o => (parseInt(age.inputValue) > 1 ? `${o.label}s` : o.label),
    [age.inputValue]
  )

  return (
    <FlexLine style={style}>
      <Input
        placeholder={placeholder}
        value={age.inputValue}
        invalid={invalid}
        onBlur={onBlur}
        onChange={onValueChange}
        onKeyPress={preventKeyPress}
        autoFocus={autoFocus}
        style={{ width: 58, marginRight: 10 }}
        inputStyle={{ textAlign: 'left' }}
        type="number"
        aria-label="interval-value"
      />
      <Select
        value={PeriodOptions.find(o => o.key === age.unit)}
        onChange={onUnitChange}
        optionToString={unitToString}
        options={options}
        optionFormatter={unitFormatter}
        style={{ width: 104, flexGrow: 1 }}
      />
    </FlexLine>
  )
}

export { TimeIntervalInput }

// @flow

import { useCombobox } from 'downshift'
import Immutable, { type List } from 'immutable'
import { debounce } from 'lodash-es'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { useWidthObserver, usePopper, useToggle } from 'components/_hooks'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Separator } from 'components/styled/utils'

import { type BaseSelectProps } from './select'
import { SelectDropdown } from './select-dropdown'
import { selectPopperConfig, type SelectMenuPropsType } from './select.helper'
import {
  SelectContainer,
  SelectField,
  SelectValueContainer,
  SelectInput,
  SelectClear,
  SelectIndicator,
  SelectPlaceholder,
} from './select.styles'

export function SelectSearch<T>({
  placeholder,
  isClearable,
  options,
  id,
  forceOpen,
  autoFocus,
  loadOptions,
  onBlur,
  onFocus,
  optionToString,
  menuOffset = 20,
  optionMenuShownCount,
  localSearchEveryWhere,
  isDisabled,
  value,
  optionCreator,
  optionFormatter,
  noResultText = 'No option found',
  noOptionText,
  optionMenuStyle,
  menuStyle,
  optionMenuHeight,
  onChange,
  tooltip,
  style,
  $noShadow,
  header,
  ...rest
}: BaseSelectProps<T>): React.Node {
  const [localOptions, setLocalOptions] = React.useState<List<T>>(options ?? new Immutable.List())
  const [cachedEmptySearchOptions, setCachedEmptySearchOptions] = React.useState<?List<T>>(
    options ?? null
  )
  const inputRef = React.useRef()
  const theme = useTheme()
  const computedDisabled = React.useMemo(() => {
    return Boolean(isDisabled || theme?.disabledMode)
  }, [isDisabled, theme?.disabledMode])
  const [triggerRef, popperRef, popperInstance] = usePopper(selectPopperConfig)
  const [inputValue, setInputValue] = React.useState('')
  const loadingState = useToggle()
  const [newValueValid, setNewValueValid] = React.useState(true)
  // ensure we populate the dropdown when options is not filled on first render
  React.useEffect(() => {
    setLocalOptions(options ?? new Immutable.List())
  }, [options])

  const loadOptionsDebounced = React.useRef(
    debounce(
      (q: string) => {
        if (loadOptions) {
          loadingState.open()
          loadOptions(q).then(options => {
            setLocalOptions(options)
            if (q === '') {
              setCachedEmptySearchOptions(options)
            }
            popperInstance?.update()
            loadingState.close()
          }, loadingState.close)
        }
      },
      200,
      { trailing: true }
    )
  )

  // new option we'll be able to create if no match
  const newOption = React.useMemo(() => {
    try {
      setNewValueValid(true)
      return localOptions.size === 0 && inputValue && optionCreator
        ? optionCreator(inputValue)
        : null
    } catch (error) {
      setNewValueValid(false)
      return null
    }
  }, [inputValue, localOptions.size, optionCreator])
  const items = React.useMemo(
    () => (newOption ? [newOption] : localOptions.toArray()),
    [localOptions, newOption]
  )

  const {
    getToggleButtonProps,
    getMenuProps,
    isOpen,
    closeMenu,
    highlightedIndex,
    setHighlightedIndex,
    getItemProps,
    getInputProps,
  } = useCombobox({
    items,
    itemToString: optionToString,
    inputValue,
    circularNavigation: true,
    selectedItem: value ?? null,
    onInputValueChange: changes => {
      if (changes.type === useCombobox.stateChangeTypes.InputChange) {
        setInputValue(changes.inputValue)
        if (loadOptions) {
          loadOptionsDebounced.current(changes.inputValue)
        } else {
          const filteredOptions = (options ? options : new Immutable.List()).filter(option =>
            localSearchEveryWhere
              ? optionToString(option).toLowerCase().includes(changes.inputValue.toLowerCase())
              : optionToString(option).toLowerCase().startsWith(changes.inputValue.toLowerCase())
          )
          setLocalOptions(filteredOptions)
        }
      }
    },
    onIsOpenChange: ({ isOpen }) => {
      if (isOpen) {
        popperInstance?.update()
        if (localOptions.size === 0 && loadOptions) {
          const inputValue = inputRef?.current?.value ?? ''

          if (inputValue === '' && cachedEmptySearchOptions) {
            setLocalOptions(cachedEmptySearchOptions)
          } else {
            // on trigger un load au premier open uniquement, sinon on prend le cache
            loadOptionsDebounced.current(inputValue)
          }
        }
      } else {
        inputRef.current?.blur()
      }
      if (onBlur && !isOpen) {
        onBlur()
      }
      if (onFocus && isOpen) {
        onFocus()
      }
    },
    onSelectedItemChange: changes => {
      onChange(changes.selectedItem)
      if (!loadOptions) setLocalOptions(options ?? new Immutable.List())
      setInputValue('')
    },
  })
  const isOptionSelected = React.useCallback((option: T) => option === value, [value])
  const showClearButton = Boolean(isClearable) && Boolean(value)

  const { innerRef, ...menuProps }: SelectMenuPropsType = getMenuProps({
    ref: popperRef,
    refKey: 'innerRef',
  })
  const buttonProps = getToggleButtonProps({
    ...rest,
  })
  const onClear = React.useCallback(
    evt => {
      evt?.stopPropagation()
      onChange(null)
      closeMenu()
    },
    [closeMenu, onChange]
  )
  const cleanPlaceholder = React.useMemo(() => {
    const rendered = !value
      ? placeholder
      : optionFormatter
        ? optionFormatter(value, { context: 'value' })
        : optionToString(value)
    return typeof rendered === 'string' ? rendered : optionToString(value)
  }, [optionFormatter, optionToString, placeholder, value])

  const inputProps = getInputProps({
    ref: inputRef,
    placeholder: cleanPlaceholder,
    disabled: computedDisabled,
    autoFocus: autoFocus ?? false,
    isClearable,
    id,
  })

  React.useLayoutEffect(() => {
    if (isOpen) {
      popperInstance?.setOptions(options => ({
        ...options,
        modifiers: [...options.modifiers, { name: 'eventListeners', enabled: true }],
      }))
    } else {
      popperInstance?.setOptions(options => ({
        ...options,
        modifiers: [...options.modifiers, { name: 'eventListeners', enabled: false }],
      }))
    }
  }, [isOpen, popperInstance])

  const width = useWidthObserver(triggerRef, 200)
  /*
    - si c'est ouvert
      - si on a une inputValue, on l'affiche
      - si on a pas d'inputValue, on affiche : 
        - le placeholder si on a pas de value
        - la value si on a une value
    - si c'est fermé, on affiche
      - le placeholder si on a pas de value
      - la value sinon
  */

  return (
    <SelectContainer
      ref={triggerRef}
      isFocused={isOpen || forceOpen}
      isDisabled={computedDisabled}
      style={style}
      $noShadow={$noShadow}
    >
      <Tooltip
        tooltip={tooltip}
        placement="top"
        maxWidth={width + menuOffset}
        minWidth={width + menuOffset}
        isTooltipEmpty={!tooltip || isOpen}
        offset={[menuOffset, 5]}
        arrow={false}
        noPadding={false}
        toggle={false}
      >
        <SelectInput
          style={{
            textIndent: 4,
            color: !newValueValid && optionCreator ? 'crimson' : 'inherit',
          }}
          type="search"
          isOpen={isOpen || forceOpen}
          isSearchable
          {...inputProps}
        />
      </Tooltip>
      <SelectField
        isDisabled={Boolean(isDisabled)}
        isFocused={isOpen || forceOpen}
        template={`minmax(100px, 1fr) ${showClearButton ? '33px 1px' : ''} 30px`}
        gap={0}
      >
        <SelectValueContainer>
          {isOpen || forceOpen ? (
            <React.Fragment>&nbsp;</React.Fragment>
          ) : value ? (
            <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {optionFormatter
                ? optionFormatter(value, { context: 'value' })
                : optionToString(value)}
            </span>
          ) : (
            <SelectPlaceholder isDisabled={Boolean(isDisabled)}>{placeholder}</SelectPlaceholder>
          )}
        </SelectValueContainer>
        {showClearButton && (
          <React.Fragment>
            <SelectClear onClick={onClear} aria-label="Clear select">
              <Icon icon="close" />
            </SelectClear>
            <Separator _margin="0" />
          </React.Fragment>
        )}
        <SelectIndicator
          {...buttonProps}
          isDisabled={Boolean(isDisabled)}
          aria-label="Toggle select"
        >
          <Icon icon="select" />
        </SelectIndicator>
      </SelectField>

      <SelectDropdown
        {...menuProps}
        innerRef={innerRef}
        width={width + menuOffset}
        optionMenuShownCount={optionMenuShownCount}
        getItemProps={getItemProps}
        menuStyle={menuStyle}
        optionMenuStyle={optionMenuStyle}
        optionToString={optionToString}
        noResultNode={
          localOptions.size === 0 ? (
            inputValue ? (
              <React.Fragment>
                <Icon size={16} style={{ marginRight: 8 }} thickness={0.3} icon="no-result" />
                <p style={{ fontSize: 14 }}>{loadingState.value ? 'Loading' : noResultText}</p>
              </React.Fragment>
            ) : loadingState.value ? (
              'Loading'
            ) : (
              noOptionText ?? placeholder
            )
          ) : null
        }
        optionMenuHeight={optionMenuHeight}
        optionFormatter={optionFormatter}
        options={localOptions}
        creatableOption={newOption}
        isOpen={isOpen || forceOpen}
        highlightedIndex={highlightedIndex}
        setHighlightedIndex={setHighlightedIndex}
        isOptionSelected={isOptionSelected}
        header={header}
      />
    </SelectContainer>
  )
}

// @flow

import * as React from 'react'
import { useTheme } from 'styled-components'

import { OptionContainer } from './radio.styles'

export type RadioType = {
  label?: React.Node,
  checked?: boolean,
  disabled?: boolean,
  className?: string,
  style?: { [string]: string | number, ... },
  onChange: Function,
  ariaLabel?: string,
  ariaLabelledBy?: string,
}

const Radio = ({
  label = '',
  checked = false,
  disabled = false,
  className,
  style,
  onChange,
  ariaLabel,
  ariaLabelledBy,
  ...rest
}: RadioType): React.Node => {
  const theme = useTheme()
  const labelId = React.useId()
  return (
    <OptionContainer className={className} style={style}>
      <input
        {...rest}
        disabled={disabled || theme?.disabledMode}
        checked={checked}
        type="radio"
        onChange={onChange}
        aria-checked={checked}
        aria-labelledby={label ? labelId : ariaLabelledBy}
        {...(!label && !ariaLabelledBy && ariaLabel ? { 'aria-label': ariaLabel } : {})}
      />
      <span className="styled-option-symbol" />
      {label && (
        <span className="styled-option-label" id={labelId}>
          {label}
        </span>
      )}
    </OptionContainer>
  )
}

export { Radio }

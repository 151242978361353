// @flow
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useLocation, NavLink } from 'react-router-dom'

import { useToggle } from 'components/_hooks/use-toggle'
import { Avatar } from 'components/common/avatar'
import { Box, BoxSection, BoxHeader, BoxTitle, BoxFooter, BoxBody } from 'components/common/box'
import { ButtonNavLink, Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Loader } from 'components/common/loader/loader'
import { Icon } from 'components/common/svg-icon'
import { Table, TableBody, TableCell, TableRow } from 'components/common/table'
import { Tag } from 'components/common/tag'
import { Tooltip } from 'components/common/tooltip'
import { InputWrapper, Input, Copy, Checkbox } from 'components/form'

import { ProjectDataManagement } from './project-data-management'

import { AppPicker } from '../app/app-picker'
import { ConsoleRequireCompanyFromRouter } from '../company/console-require-company-from-router'
import { Subtitle } from '../console.style'
import { createProjectAppsSelector, fetchApp } from '../redux/app'
import { type State } from '../redux/console.records'
import { fetchProject, saveProject } from '../redux/project'
import { type CompanyRecord } from 'com.batch.redux/_records'
import { ProjectFactory } from 'com.batch.redux/project.records'

const projectLoadingSelector = (state: State) => state.project.loading
const projectsSelector = (state: State) => state.project.entities

const coercePlatform = (p: string): ProjectPlatforms =>
  p === 'ios' ? 'ios' : p === 'android' ? 'android' : 'webpush'

export const ProjectView = (): React.Node => {
  const savingState = useToggle()
  // -- from URL
  const { projectKey } = useParams()
  const location = useLocation()
  const queryParams = React.useMemo(() => new URLSearchParams(location.search), [location])
  // -- from redux
  const dispatch = useDispatch()
  const projectLoading = useSelector(projectLoadingSelector)
  const projects = useSelector(projectsSelector)
  const getAppsForProjectKey = useSelector(createProjectAppsSelector)

  // -- derived
  const queryStringFedIds = React.useMemo(() => {
    return (queryParams.get('appIds') ?? '')
      .split(',')
      .filter(Boolean)
      .map(idAndPlatform => {
        const [id, plat] = idAndPlatform.split('-')
        return {
          platform: coercePlatform(plat),
          id: parseInt(id),
        }
      })
  }, [queryParams])

  const apps = React.useMemo(() => {
    return getAppsForProjectKey(projectKey ?? '')
  }, [getAppsForProjectKey, projectKey])

  const project = React.useMemo(
    () => projects.get(projectKey ?? '', ProjectFactory()),
    [projectKey, projects]
  )
  const [appIdForPlatform, setAppIdForPlatform] = React.useState({
    ios:
      queryStringFedIds.find(o => o.platform === 'ios')?.id ??
      apps.find(app => app.platform === 'ios')?.id ??
      -1,
    android:
      queryStringFedIds.find(o => o.platform === 'android')?.id ??
      apps.find(app => app.platform === 'android')?.id ??
      -1,
    webpush:
      queryStringFedIds.find(o => o.platform === 'webpush')?.id ??
      apps.find(app => app.platform === 'webpush')?.id ??
      -1,
  })
  const [name, setName] = React.useState(project.name)
  const [companyId, setCompanyId] = React.useState(project.companyId)
  const [smsConfigured, setSmsConfigured] = React.useState(project.smsConfigured)
  const [pushConfigured, setPushConfigured] = React.useState(project.pushConfigured)
  const [emailConfigured, setEmailConfigured] = React.useState(project.emailConfigured)
  const canSave = React.useMemo(() => {
    return (
      name.length > 2 &&
      !!companyId &&
      (appIdForPlatform.ios !== -1 ||
        appIdForPlatform.android !== -1 ||
        appIdForPlatform.webpush !== -1)
    )
  }, [appIdForPlatform.android, appIdForPlatform.ios, appIdForPlatform.webpush, companyId, name])

  // -- callbacks

  const renderCompany = React.useCallback(
    (company: CompanyRecord) => (
      <Grid template="40px 1fr">
        <NavLink to={`/console/company/${company.id}`}>
          <Avatar url={company.avatarUrl} placeholder={company.name} color={'#1c2e43'} />
        </NavLink>
        <Subtitle>
          <NavLink to={`/console/company/${company.id}`}>
            {company.name ? company.name : company.id}&nbsp;
            <Tooltip tooltip="Company plan">
              <Tag>
                {company.billing.trialIsActive && company.billing.trial
                  ? company.billing.trial.name
                  : company.billing.plan.name}{' '}
                plan
              </Tag>
            </Tooltip>
          </NavLink>
        </Subtitle>
      </Grid>
    ),
    []
  )

  const createOnAppChange = React.useCallback(
    (p: ProjectPlatforms) => app => {
      if (app) {
        dispatch({ type: 'FETCH_APP_SUCCESS', payload: app })
        setCompanyId(app.companyId)
      }
      setAppIdForPlatform(idsPerPlatform => {
        return {
          ...idsPerPlatform,
          [(p: string)]: app ? app.id : -1,
        }
      })
    },
    [dispatch]
  )
  const onProjectSave = React.useCallback(() => {
    savingState.open()
    dispatch(
      saveProject(
        project
          .set('emailConfigured', emailConfigured ?? '')
          .set('name', name)
          .set('smsConfigured', smsConfigured)
          .set('pushConfigured', pushConfigured),
        [appIdForPlatform.android, appIdForPlatform.ios, appIdForPlatform.webpush].filter(
          id => id > 0
        )
      )
    ).then(
      updatedProject => {
        window.location.href = `/console/projects/${updatedProject.projectKey}`
        savingState.close()
      },
      err => {
        savingState.close()
        console.log(err)
        alert(err)
      }
    )
  }, [
    savingState,
    dispatch,
    project,
    emailConfigured,
    name,
    smsConfigured,
    pushConfigured,
    appIdForPlatform.android,
    appIdForPlatform.ios,
    appIdForPlatform.webpush,
  ])

  const onSmsConfiguredChange = React.useCallback(() => setSmsConfigured(sms => !sms), [])
  const onEmailConfiguredChange = React.useCallback(() => setEmailConfigured(email => !email), [])
  const onPushConfiguredChange = React.useCallback(() => setPushConfigured(push => !push), [])
  const onNameChange = React.useCallback(evt => {
    setName(evt.target.value)
  }, [])

  // -- effects

  React.useEffect(() => {
    if (
      projectKey &&
      projectKey !== 'new' &&
      projectKey !== project.projectKey &&
      !projectLoading
    ) {
      dispatch(fetchProject(projectKey)).then(({ apps, project }) => {
        setEmailConfigured(project.emailConfigured)
        setName(project.name)
        setCompanyId(project.companyId)
        setSmsConfigured(project.smsConfigured)
        setPushConfigured(project.pushConfigured)
        setAppIdForPlatform(
          apps.reduce(
            (acc, app) => {
              acc[coercePlatform(app.platform)] = app.id
              return acc
            },
            {
              ios: -1,
              android: -1,
              webpush: -1,
            }
          )
        )
      }, console.error)
    }
  }, [dispatch, project.projectKey, projectKey, projectLoading])

  // trigger queryStringFedIds app fetch
  React.useEffect(() => {
    queryStringFedIds.forEach(({ id }) => {
      if (id > 0)
        dispatch(fetchApp(id)).then(app => {
          setCompanyId(app.companyId)
        }, console.error)
    })
  }, [dispatch, queryStringFedIds])

  return (
    <Loader loading={projectLoading}>
      <Grid template="auto 20px 1fr" alignItems="center" style={{ marginBottom: 25 }}>
        <NavLink
          to={
            apps.size > 0
              ? `/${project.companyId}/projects/${project.id}/apps/${apps.first()?.id}`
              : `/${project.companyId}/projects/${project.id}/automations/email`
          }
          style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
          target="_blank"
        >
          <Avatar url={project.iconUrl} placeholder={project.name} color={'#1c2e43'} />
          <Subtitle>
            {name || 'New project'}
            <Tag>PROJECT</Tag>
          </Subtitle>
        </NavLink>
        <div>by</div>
        {companyId && (
          <ConsoleRequireCompanyFromRouter forcedCompanyId={companyId} render={renderCompany} />
        )}
      </Grid>
      <Box>
        <BoxHeader>
          <BoxTitle>{project.id ? 'Project configuration' : 'Create new project'}</BoxTitle>
        </BoxHeader>
        <BoxBody>
          <Grid template="1fr 300px" alignItems="stretch" gap={0}>
            <BoxSection $padding>
              <Table template="36px 400px auto">
                <TableBody>
                  {['android', 'ios', 'webpush'].map((p: ProjectPlatforms) => (
                    <TableRow key={p}>
                      <TableCell>
                        <Icon icon={p} />
                      </TableCell>
                      <TableCell>
                        <AppPicker
                          key={companyId}
                          width={400}
                          isClearable
                          companyId={companyId}
                          platform={p}
                          noProject
                          placeholder="Pick an app"
                          appId={appIdForPlatform[p]}
                          setApp={createOnAppChange(p)}
                        />
                      </TableCell>
                      <TableCell>
                        <ButtonNavLink
                          addOn="prefix"
                          to={`/console/apps/${appIdForPlatform[p]}`}
                          disabled={appIdForPlatform[p] === -1}
                        >
                          <Icon icon="view" />
                          App
                        </ButtonNavLink>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell>
                      <Icon icon="mail" />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={emailConfigured}
                        onChange={onEmailConfiguredChange}
                        label="Email configured on project"
                      />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Icon icon="sms" />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={smsConfigured}
                        onChange={onSmsConfiguredChange}
                        label="SMS configured on project"
                      />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Icon icon="push" />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={pushConfigured}
                        onChange={onPushConfiguredChange}
                        label="Push configured on project"
                      />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </BoxSection>
            <BoxSection $padding>
              <InputWrapper label="Project name" htmlFor="project-name">
                <Input id="project-name" value={name} onChange={onNameChange} />
              </InputWrapper>
              {project.id && (
                <Copy label="Project Key" value={project.projectKey} hint="Used by API" />
              )}
            </BoxSection>
          </Grid>
        </BoxBody>

        <BoxFooter>
          <Button
            kind="primary"
            intent="action"
            onClick={onProjectSave}
            disabled={!canSave}
            isLoading={savingState.value}
          >
            Save and sync project
          </Button>
        </BoxFooter>
      </Box>
      {project.projectKey && <ProjectDataManagement />}
    </Loader>
  )
}

// @flow
import Immutable, { type List, type Map, type Set } from 'immutable'
import { createSelector } from 'reselect'

import languagesRaw from 'com.batch.common/languages'

import { type MessageStateRecord } from './message.state'

import {
  type Extract,
  LanguageFactory,
  type LanguageRecord,
  type RegionRecord,
  type State,
} from 'com.batch.redux/_records'
import { valueSelector } from 'com.batch.redux/attribute.selector'

// all the languages we allow for targeting / translation
export const languages: List<LanguageRecord> = new Immutable.List().push(
  ...Object.keys(languagesRaw).map(k =>
    LanguageFactory({
      value: k,
      label: languagesRaw[k],
      nb: 0,
      tokens: 0,
      pcent: '0.0%',
    })
  )
)

export const getLangFromContent: (Map<string, mixed>, boolean) => List<LanguageRecord> = (
  content,
  multilanguageEnabled
) =>
  multilanguageEnabled
    ? content.map((_, k) => languages.find(rl => rl.get('value') === k)).toList()
    : languages.filter(lang => lang.get('value') === 'default')

const langOrRegionSorter: (
  a: LanguageRecord | RegionRecord,
  b: LanguageRecord | RegionRecord
) => 1 | -1 = (a, b) => {
  if (parseInt(a.nb) < parseInt(b.nb)) {
    return 1
  }
  if (parseInt(a.nb) > parseInt(b.nb)) {
    return -1
  }
  return a.label < b.label ? -1 : 1
}

export type MessageIdGetterSelector<T> = State => (messageId: string) => T

export const getMessageKey = (lang: LanguageRecord): string =>
  `Message${lang.value === 'default' ? '' : ` (${lang.label.toLowerCase()})`}`

export const messageStateSelector: Extract<MessageStateRecord> = state => state.message
export const previewLanguageSelector: Extract<string> = createSelector(
  messageStateSelector,
  messageState => messageState.previewLanguage
)

export const enrichedLanguagesSelector: State => List<LanguageRecord> = createSelector(
  valueSelector,
  values => {
    const languagesValues = values.getIn(
      ['b.language', 'values', '__default'],
      new Immutable.List()
    )
    return languages
      .map(lang => {
        const match = languagesValues.find(lv => lv.value.toString() === lang.value, false)
        return match
          ? lang.set('nb', match.installs).set('tokens', match.tokens).set('pcent', match.pcent)
          : lang
      })
      .sort(langOrRegionSorter)
      .map(lang => lang.set('pretty', lang.label))
  }
)

export const getLangKeys: ({
  messageId: string,
  channel: ChannelUntilCleanup,
  messageState: MessageStateRecord,
}) => Iterator<string> = ({ messageId, channel, messageState }) => {
  switch (channel) {
    case 'email':
      return messageState.getIn(['email', messageId, 'localizedContent'], Immutable.Map()).keys()
    case 'push':
      return messageState.getIn(['push', messageId, 'localizedContent'], Immutable.Map()).keys()
    case 'sms':
      return messageState.getIn(['sms', messageId, 'localizedContent'], Immutable.Map()).keys()
  }
  return Immutable.Map().keys()
}

export const globallyConfiguredLanguagesSelector: Extract<Set<string>> = createSelector(
  messageStateSelector,
  messageState => {
    const emailLangKeys: Set<string> = messageState
      .get('email', Immutable.Map())
      .reduce(
        (acc, messageId) => acc.union(messageId.get('localizedContent', Immutable.Map()).keys()),
        new Immutable.Set()
      )
    const pushLangKeys: Set<string> = messageState
      .get('push', Immutable.Map())
      .reduce(
        (acc, messageId) => acc.union(messageId.get('localizedContent', Immutable.Map()).keys()),
        new Immutable.Set()
      )
    const smsLangKeys: Set<string> = messageState
      .get('sms', Immutable.Map())
      .reduce(
        (acc, messageId) => acc.union(messageId.get('localizedContent', Immutable.Map()).keys()),
        new Immutable.Set()
      )
    return new Immutable.Set().union(emailLangKeys, pushLangKeys, smsLangKeys)
  }
)

export const pickedLanguagesSelector: ({
  messageId: string,
  channel: ChannelUntilCleanup,
}) => State => List<LanguageRecord> = ({ messageId, channel }) =>
  createSelector(
    messageStateSelector,
    enrichedLanguagesSelector,
    (messageState: MessageStateRecord, enrichedLanguages) => {
      let alreadyPickedLanguagesIds = getLangKeys({ messageId, channel, messageState })

      const pickedLanguagesList = Immutable.List(alreadyPickedLanguagesIds)
      return pickedLanguagesList
        .map(v => {
          return enrichedLanguages.find(rl => rl.get('value') === v)
        })
        .sort((a, b) =>
          pickedLanguagesList.indexOf(a.value) > pickedLanguagesList.indexOf(b.value) ? 1 : -1
        )
    }
  )

export const pickableLanguagesSelector: ({
  messageId: string,
  channel: ChannelUntilCleanup,
}) => State => List<LanguageRecord> = ({ messageId, channel }) =>
  createSelector(messageStateSelector, enrichedLanguagesSelector, (messageState, langs) => {
    const langKeys = getLangKeys({ messageId, channel, messageState })
    const alreadyPickedLanguagesIds = langKeys ? [...langKeys] : []

    return langs.filter(
      lang => lang.value !== 'default' && !alreadyPickedLanguagesIds.includes(lang.value)
    )
  })

export const multilanguageEnabledSelector: ({
  messageId: string,
  channel: ChannelUntilCleanup,
}) => State => boolean = ({ messageId, channel }) =>
  createSelector(messageStateSelector, (messageState: MessageStateRecord) => {
    switch (channel) {
      case 'email':
        return messageState.getIn(['email', messageId, 'multilanguageEnabled'], false)
      case 'push':
        return messageState.getIn(['push', messageId, 'multilanguageEnabled'], false)
      case 'sms':
        return messageState.getIn(['sms', messageId, 'multilanguageEnabled'], false)
      default:
        return false
    }
  })
export const getMultilanguageEnabledSelector: State => ({
  messageId: string,
  channel: ChannelUntilCleanup,
}) => boolean = createSelector(
  messageStateSelector,
  (messageState: MessageStateRecord) =>
    ({ messageId, channel }) => {
      switch (channel) {
        case 'email':
          return messageState.getIn(['email', messageId, 'multilanguageEnabled'], false)
        case 'push':
          return messageState.getIn(['push', messageId, 'multilanguageEnabled'], false)
        case 'sms':
          return messageState.getIn(['sms', messageId, 'multilanguageEnabled'], false)
        default:
          return false
      }
    }
)

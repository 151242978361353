// @flow
import Editor from '@monaco-editor/react'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import { type StateToggler } from 'components/_hooks'

import { getEmailContentForActiveLanguageSelector } from 'com.batch/email/store/email-automation.selector'
import {
  getMultilanguageEnabledSelector,
  previewLanguageSelector,
} from 'com.batch/message/store/message.selector'

import { loadZipScript } from 'com.batch/email/infra/services/email-uploader.helper'
import { EmailEditorPopin } from 'com.batch/email/ui/components/email-editor-popin'
import { EmailPreview } from 'com.batch/email/ui/components/email-preview/email-preview'
import { updateEmailContent } from 'com.batch/email/usecases/update-email-content'
import { LangPicker } from 'com.batch/message-builder/ui/components/lang-picker/lang-picker'
import { useLeaveConfirmation } from 'com.batch/shared/ui/hooks/use-leave-confirmation'

type CodeEditorProps = {
  popinState: StateToggler,
  messageId: string,
  sendTestEmailPopinState: StateToggler,
}
export const CodeEditor = ({
  popinState,
  messageId,
  sendTestEmailPopinState,
}: CodeEditorProps): React.Node => {
  const dispatch = useDispatch()
  const getContent = useSelector(getEmailContentForActiveLanguageSelector)
  const content = React.useMemo(() => getContent(messageId), [getContent, messageId])
  const lang = useSelector(previewLanguageSelector)
  const theme = useTheme()
  const htmlBackup = React.useRef(content?.html ?? '')
  const isPristine = React.useMemo(() => content?.isPristine ?? true, [content?.isPristine])
  const { confirmBeforeAction } = useLeaveConfirmation({ isPristine })

  // since monaco break loading of zip file, we load it before
  const [ready, setReady] = React.useState(false)
  React.useEffect(() => {
    loadZipScript().then(() => setReady(true))
  }, [])

  const handleUpdateHtml = React.useCallback(
    (value: string) => {
      return dispatch(
        updateEmailContent({
          messageId,
          lang,
          field: 'html',
          value,
          htmlEditorConfig: { type: 'CODE' },
          timeout: 1600,
        })
      )
    },
    [dispatch, lang, messageId]
  )

  const dismissPopin = React.useCallback(async () => {
    const confirmed = await confirmBeforeAction()
    if (confirmed) {
      dispatch(
        updateEmailContent({
          messageId,
          lang,
          field: 'html',
          value: htmlBackup.current,
          htmlEditorConfig: { type: 'CODE' },
          isInstant: true,
        })
      )
      popinState.close()
    }
  }, [confirmBeforeAction, dispatch, lang, messageId, popinState])

  const onBeforeMount = React.useCallback(monaco => {
    monaco.editor.defineTheme('my-theme', {
      base: 'vs-dark',
      inherit: true,
      rules: [],
      colors: {
        'editor.paddingTop': '16px',
        'editor.foreground': '#C5C8C6',
        'editor.background': '#383838',
        'editor.selectionBackground': '#0863a3',
        'editor.lineHighlightBackground': '#054562',
        'editorCursor.foreground': '#AEAFAD',
        'editorWhitespace.foreground': '#4B4E55',
      },
    })
  }, [])

  const getMultilanguageEnabled = useSelector(getMultilanguageEnabledSelector)
  const multilanguageEnabled = React.useMemo(
    () => getMultilanguageEnabled({ messageId, channel: 'email' }),
    [getMultilanguageEnabled, messageId]
  )

  if (!ready) return null
  return (
    <EmailEditorPopin
      messageId={messageId}
      popinState={popinState}
      saveAction={popinState.close}
      cancelAction={dismissPopin}
      sendTestEmailPopinState={sendTestEmailPopinState}
    >
      {multilanguageEnabled && <LangPicker messageId={messageId} channel="email" />}
      <div style={{ display: 'flex', height: '100%' }}>
        <div
          style={{
            flexBasis: 600,
            height: '100%',
            flexShrink: 0,
            background: 'rgb(56, 56, 56)',
            paddingTop: 16,
          }}
          data-testid="code-editor"
        >
          <Editor
            height="100%"
            defaultLanguage="html"
            theme="my-theme"
            value={content?.html ?? ''}
            options={{
              minimap: {
                enabled: false,
              },
              scrollBeyondLastLine: false,
              fontSize: 14,
              wordWrap: 'on',
              readOnly: theme?.disabledMode,
            }}
            onChange={handleUpdateHtml}
            beforeMount={onBeforeMount}
          />
        </div>
        <div
          style={{
            minHeight: '100%',
            flexBasis: 680,
            flexGrow: 1,
            overflow: content ? 'auto' : 'hidden',
            background: 'rgb(248, 248, 248)',
          }}
        >
          <EmailPreview messageId={messageId} />
        </div>
      </div>
    </EmailEditorPopin>
  )
}

// @flow

import { ReactFlowProvider } from '@xyflow/react'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ButtonNavLink } from 'components/common/button/button.styles'
import { Wrapper } from 'components/common/empty-states'
import { Loader } from 'components/common/loader/loader'

import { type ChannelInUrl, generateOrchestrationUrl } from 'com.batch.common/router'
import { generateTypedId } from 'com.batch.common/typed-id'

import { orchestrationMetaSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import { type pushCampaignSendType } from 'com.batch.redux/_records'
import { fetchProjectApps } from 'com.batch.redux/app.action'
import { senderIdentityLoadingStateSelector } from 'com.batch.redux/corelogic/selectors/sender-identity.selector'
import { fetchSenderIdentities } from 'com.batch.redux/corelogic/usecases/sender-identity/fetch-sender-identities'
import { fetchUnifiedCustomerDataSummary } from 'com.batch.redux/corelogic/usecases/unified-customer-data/fetch-unified-customer-data-summary'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { resetTarget } from 'com.batch.redux/target/target'
import { TargetStateFactory } from 'com.batch.redux/target/target.records'
import { attributesLoadedSelector } from 'com.batch.redux/target/target.selector'

import { fetchLabelsAndCappingRules } from 'com.batch/labels/usecases/fetch-labels-and-capping-rules'
import { OrchestrationHeader } from 'com.batch/orchestration/ui/components/header'
import { fetchOrchestration } from 'com.batch/orchestration/usecases/fetch-orchestration'
import { initForm } from 'com.batch/orchestration/usecases/init-form'
import { triggerEstimate } from 'com.batch/orchestration/usecases/trigger-estimate'
import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'
import { initDefaultAdvancedSettingsForCampaign } from 'com.batch/push/usecases/init-default-advanced-settings'
import { fetchSspConfiguration } from 'com.batch/sms/usecases/fetch-ssp-configuration'
import { STATUS } from 'constants/common'

type OrchestrationLoaderProps = {
  children: React.Node,
  defaultSendType?: pushCampaignSendType,
  isAnalytics?: boolean,
}
const refresh = () => window.location.reload()
export const OrchestrationLoader: React.ComponentType<OrchestrationLoaderProps> = React.memo(
  ({ children, isAnalytics, defaultSendType }: OrchestrationLoaderProps): React.Node => {
    const dispatch = useDispatch()

    const { token, channel } = useParams()

    // channel is not present on trigger, and could be changed, so we can't trust it

    const coercedChannelForUrl: ChannelInUrl = React.useMemo(() => {
      if (channel === 'email' || channel === 'sms' || channel === 'push') {
        return channel
      }
      return null
    }, [channel])

    const coercedChannelUntilChange: ChannelUntilCleanup = React.useMemo(() => {
      if (channel === 'email' || channel === 'sms' || channel === 'push') {
        return channel
      }
      return 'email'
    }, [channel])
    const currentProject = useSelector(currentProjectSelector)
    const { loadingState, sendType, name } = useSelector(orchestrationMetaSelector)
    const isLoading = React.useMemo(() => loadingState === STATUS.LOADING, [loadingState])
    const schedulingTypeFromUrl = useGetSchedulingTypeFromUrl()
    const senderIdState = useSelector(senderIdentityLoadingStateSelector)
    const attributesLoaded = useSelector(attributesLoadedSelector)

    React.useEffect(() => {
      if (!attributesLoaded) {
        dispatch(fetchUnifiedCustomerDataSummary()).catch(() => {})
      }
    }, [dispatch, attributesLoaded])
    React.useEffect(() => {
      if (!['LOADING', 'LOADED', 'ERROR'].includes(senderIdState)) {
        dispatch(fetchSenderIdentities()).catch(console.error)
      }
    })
    React.useEffect(() => {
      dispatch(resetTarget(TargetStateFactory(), true))
      dispatch(
        initForm({
          schedulingType: schedulingTypeFromUrl,
          sendType: defaultSendType ?? 'now',
          // type is used for legacy reasons
          type: 'email',
          channel: coercedChannelUntilChange,
          messageTypedId: generateTypedId('message'),
        })
      )
      if (token) {
        dispatch(fetchOrchestration(token)).catch(() => {})
      }
      if (!token) {
        dispatch(triggerEstimate())
      }
    }, [dispatch, token, schedulingTypeFromUrl, defaultSendType, coercedChannelUntilChange])

    React.useEffect(() => {
      if (loadingState !== STATUS.LOADED && loadingState !== STATUS.LOADING) {
        dispatch(fetchLabelsAndCappingRules())
        dispatch(fetchSspConfiguration())
      }
    }, [dispatch, loadingState])

    React.useEffect(() => {
      dispatch(fetchProjectApps(currentProject)).then(() => {
        dispatch(initDefaultAdvancedSettingsForCampaign())
      })
    }, [dispatch, currentProject])

    const sendTypeMismatch = React.useMemo(() => {
      if (loadingState !== 'LOADED') return false

      if (schedulingTypeFromUrl === 'campaigns')
        return sendType !== 'scheduled' && sendType !== 'now'
      return sendType !== defaultSendType
    }, [defaultSendType, loadingState, schedulingTypeFromUrl, sendType])
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {schedulingTypeFromUrl === 'automations' ? 'Automation' : 'Campaign'} —{' '}
            {isAnalytics ? 'Analytics' : token ? 'Edit' : 'New'} – {currentProject.name} – Batch
          </title>
        </Helmet>
        <Loader loading={isLoading} padding={100} style={isLoading ? { marginTop: 20 } : {}}>
          {loadingState === 'ERROR' || sendTypeMismatch ? (
            <Wrapper
              isOverlayShown
              isLoading={false}
              isEmpty={false}
              overlayProps={{
                title: sendTypeMismatch
                  ? 'Incorrect orchestration type'
                  : 'Unable to load your orchestration',
                description: sendTypeMismatch ? (
                  <div style={{ maxWidth: 320 }}>
                    <p style={{ marginBottom: 20 }}>
                      Current URL is not valid. If a link in our dashboard led you there, please
                      contact us.
                    </p>
                    <p style={{ marginBottom: 20 }}>
                      The orchestration you seem to be trying to open should be available here :{' '}
                    </p>
                    <div>
                      <ButtonNavLink
                        intent="action"
                        kind="secondary"
                        to={generateOrchestrationUrl({
                          sendType,
                          channel: coercedChannelForUrl,
                          projectId: currentProject.id,
                          companyId: currentProject.companyId,
                          page: 'form',
                          token,
                        })}
                      >
                        <span
                          style={{
                            maxWidth: 280,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {name}
                        </span>
                      </ButtonNavLink>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    An unexpected error occured while loading orchestration <code>{token}</code>
                  </React.Fragment>
                ),
                status: 'error-page',
                refresh: !sendTypeMismatch ? refresh : undefined,
              }}
            >
              &nbsp;
            </Wrapper>
          ) : (
            <React.Fragment>
              <OrchestrationHeader forceDisabled={isAnalytics} channel={channel} />
              {defaultSendType === 'trigger' ? (
                <ReactFlowProvider>{children}</ReactFlowProvider>
              ) : (
                children
              )}
            </React.Fragment>
          )}
        </Loader>
      </React.Fragment>
    )
  }
)
OrchestrationLoader.displayName = 'OrchestrationLoader'

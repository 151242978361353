/* eslint-disable react/jsx-no-bind */
// @flow

import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css, type StyledComponent } from 'styled-components'

import { useFetchAppFromRouter } from 'components/_hooks'
import {
  AnalyticsMetricsEmptyTemplate,
  AnalyticsListEmptyTemplate,
} from 'components/analytics/analytics-utils'
import { Map } from 'components/charts/map'
import { Box, BoxBody, BoxSection } from 'components/common/box'
import { Button } from 'components/common/button'
import {
  Wrapper,
  AnalyticsEmptyIcon,
  GlobalErrorOverlayProps,
} from 'components/common/empty-states'
import { FlexLine } from 'components/common/flexline'
import { Pager } from 'components/common/pager'
import {
  Table,
  TableCellOrder,
  TableHeader,
  TableRow,
  TableCell,
  TableFooter,
  TableBody,
} from 'components/common/table'
import { schemes } from 'components/styled/tokens'

import { kformat } from 'com.batch.common/utils'

import { AnalyticsChart } from './analytics-chart'
import { AnalyticsMetric } from './analytics-metric'
import { AnalyticsMetricItem } from './analytics-metric-item'
import { LocationMenu, FlagImage } from './analytics.styles'

import {
  setAnalyticsTab,
  toggleSecret,
  fetchAnalyticsByDay,
  fetchAnalyticsByRegion,
} from 'com.batch.redux/stat'
import {
  analyticsIsEmptySelector,
  analyticsOverviewSelector,
  analyticsRegionSelector,
  analyticsSeriesSelector,
  loadingOverviewSelector,
  loadingRegionSelector,
  rangeSelector,
} from 'com.batch.redux/stat.selector.analytics'

import { STATUS } from 'constants/common'

const NB_PER_PAGE = 9

type RegionValue = { value: number, regionCode: string, regionLabel: string, color: string, ... }
type RegionSerie = {
  color: string,
  title: string,
  values: Array<RegionValue>,
  ...
}

export const Analytics = (): React.Node => {
  // ====================== STATE
  const [activeRegionSerieIndex, setActiveRegionSerieIndex] = React.useState<number>(0)
  const [regionOrderDir, setRegionOrderDir] = React.useState<
    'asc-count' | 'dsc-count' | 'asc-name' | 'dsc-name',
  >('dsc-count')
  const [regionPage, setRegionPage] = React.useState<number>(1)
  const app = useFetchAppFromRouter()

  // ====================== REDUX
  const dispatch = useDispatch()
  const isEmpty = useSelector(analyticsIsEmptySelector)
  const overview = useSelector(analyticsOverviewSelector)
  const overviewStatus = useSelector(loadingOverviewSelector)
  const regionSeries = useSelector(analyticsRegionSelector)
  const regionStatus = useSelector(loadingRegionSelector)
  const series = useSelector(analyticsSeriesSelector)
  const { from, to } = useSelector(rangeSelector)

  // ====================== DERIVED STATE
  /* 
    We want to keep the loading state until both overview and region series are loaded, 
    so we can have a full page error / empty state
  */
  const isLoading = React.useMemo(() => {
    return (
      overviewStatus === STATUS.LOADING ||
      overviewStatus === STATUS.INIT ||
      regionStatus === STATUS.LOADING ||
      regionStatus === STATUS.INIT
    )
  }, [overviewStatus, regionStatus])

  // ====================== EMPTY STATE GLOBAL
  const globalError = React.useMemo(
    () => overviewStatus === STATUS.ERROR && regionStatus === STATUS.ERROR,
    [overviewStatus, regionStatus]
  )

  const isEmptyAfterLoading = React.useMemo(
    () => isEmpty && overviewStatus === STATUS.LOADED && regionStatus === STATUS.LOADED,
    [isEmpty, overviewStatus, regionStatus]
  )
  const overviewErrorOnly = React.useMemo(
    () => overviewStatus === STATUS.ERROR && regionStatus === STATUS.LOADED,
    [overviewStatus, regionStatus]
  )
  const regionLoading = React.useMemo(
    () => regionStatus === STATUS.LOADING || regionStatus === STATUS.INIT,
    [regionStatus]
  )
  const regionSerie = regionSeries[activeRegionSerieIndex]

  const paramFetching = { from: from, to: to, dimension: 'none', devMode: false }

  const getPageAndSorted = React.useCallback(
    (series: RegionSerie, page: number) =>
      (series.values
        .sort((a, b) => {
          const ok = regionOrderDir === 'asc-count' || regionOrderDir === 'asc-name' ? 1 : -1
          const ko = ok === 1 ? -1 : 1
          return regionOrderDir === 'asc-count' || regionOrderDir === 'dsc-count'
            ? a.value > b.value
              ? ok
              : ko
            : a.regionLabel > b.regionLabel
              ? ok
              : ko
        })
        .slice((page - 1) * NB_PER_PAGE, page * NB_PER_PAGE): Array<RegionValue>),
    [regionOrderDir]
  )
  const onToggleSecret = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'h') {
        dispatch(toggleSecret())
      }
    },
    [dispatch]
  )

  React.useEffect(() => {
    dispatch(setAnalyticsTab('analytics'))
  }, [dispatch])

  React.useEffect(() => {
    document.addEventListener('keyup', onToggleSecret)

    return () => document.removeEventListener('keyup', onToggleSecret)
  }, [onToggleSecret])

  let pagedAndSorted = getPageAndSorted(regionSerie, regionPage)

  return (
    <Wrapper
      isEmpty={globalError || isEmptyAfterLoading}
      isLoading={isLoading}
      isOverlayShown={globalError || isEmptyAfterLoading}
      overlayProps={
        globalError
          ? GlobalErrorOverlayProps
          : {
              content: <AnalyticsEmptyIcon />,
              title: 'No data for this date range',
              description:
                'We haven’t been able to find any data for the selected dates. If the SDK is up and running, you may need to select a different/larger date range',
              status: 'empty-page',
              links: [
                {
                  name: 'Troubleshooting',
                  href: 'https://doc.batch.com/dashboard/analytics/troubleshooting.html',
                },
                {
                  name: 'Install the SDK',
                  href: 'https://batch.com/download',
                },
              ],
            }
      }
    >
      <Helmet>
        <title>Analytics — {app?.name}</title>
      </Helmet>
      <AnalyticsBox isLoading={isLoading}>
        <Wrapper
          isEmpty={isEmptyAfterLoading}
          isLoading={isLoading}
          isOverlayShown={overviewErrorOnly}
          overlayProps={{
            status: 'error',
            title: 'Something went wrong',
            refresh: () => {
              dispatch(fetchAnalyticsByDay(paramFetching))
            },
          }}
        >
          <BoxBody>
            <FlexLine sameHeight>
              <AnalyticsMetric emptyTemplate={<AnalyticsMetricsEmptyTemplate />}>
                {overview.map((item, i) => (
                  <AnalyticsMetricItem
                    {...item}
                    key={i}
                    style={{
                      borderLeft: i !== 0 ? `1px solid ${schemes.darklucent['05']}` : 'none',
                      borderTopLeftRadius: i === 0 ? '4px' : '0',
                      borderTopRightRadius: i === overview.length - 1 ? '4px' : '0',
                    }}
                  />
                ))}
              </AnalyticsMetric>
            </FlexLine>
          </BoxBody>
        </Wrapper>
      </AnalyticsBox>
      <Box>
        <Wrapper
          isEmpty={isEmptyAfterLoading}
          isLoading={isLoading}
          isOverlayShown={overviewErrorOnly}
          overlayProps={{
            status: 'error',
            title: 'Something went wrong',
            refresh: () => {
              dispatch(fetchAnalyticsByDay(paramFetching))
            },
          }}
        >
          <AnalyticsChart series={series} />
        </Wrapper>
      </Box>

      <Box style={{ overflow: 'hidden' }}>
        <Wrapper
          isOverlayShown={
            !globalError &&
            (regionStatus === STATUS.ERROR ||
              (regionStatus === STATUS.LOADED && pagedAndSorted.length <= 0))
          }
          isLoading={regionStatus === STATUS.LOADING || regionStatus === STATUS.INIT}
          isEmpty={regionStatus === STATUS.ERROR || pagedAndSorted.length <= 0}
          overlayProps={
            pagedAndSorted.length <= 0
              ? {
                  status: 'empty',
                  title: 'No geographic data',
                }
              : {
                  status: 'error',
                  title: 'Something went wong',
                  refresh: () => {
                    dispatch(fetchAnalyticsByRegion(paramFetching))
                  },
                }
          }
        >
          <FlexLine sameHeight>
            <BoxSection back style={{ flex: 1, borderRadius: '8px 0 0 8px' }}>
              <LocationMenu>
                {regionSeries.map((serie, i) => (
                  <Button
                    isActive={i === activeRegionSerieIndex}
                    onClick={() => setActiveRegionSerieIndex(i)}
                    key={i}
                  >
                    <i style={{ backgroundColor: serie.color }} />
                    {serie.title}
                  </Button>
                ))}
              </LocationMenu>
              <Map
                topologyUrl="/json/world_countries.json"
                serie={regionSerie}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: 'calc(100% - 18px)',
                  top: 18,
                }}
              />
            </BoxSection>
            <BoxSection style={{ width: 340, minHeight: 496, borderRadius: '0 8px 8px 0' }}>
              <div style={{ minHeight: 480 }}>
                <Table template="1fr 1fr">
                  <TableHeader>
                    <TableCellOrder
                      sort={
                        regionOrderDir === 'asc-name'
                          ? 'asc'
                          : regionOrderDir === 'dsc-name'
                            ? 'dsc'
                            : false
                      }
                      onClick={() =>
                        setRegionOrderDir(regionOrderDir === 'asc-name' ? 'dsc-name' : 'asc-name')
                      }
                    >
                      Country
                    </TableCellOrder>

                    <TableCellOrder
                      align="right"
                      sort={
                        regionOrderDir === 'asc-count'
                          ? 'asc'
                          : regionOrderDir === 'dsc-count'
                            ? 'dsc'
                            : false
                      }
                      onClick={() =>
                        setRegionOrderDir(
                          regionOrderDir === 'asc-count' ? 'dsc-count' : 'asc-count'
                        )
                      }
                    >
                      {regionSeries[activeRegionSerieIndex].title}
                    </TableCellOrder>
                  </TableHeader>
                  <TableBody emptyTemplate={<AnalyticsListEmptyTemplate />}>
                    {(regionLoading || pagedAndSorted.length <= 0
                      ? emptyRegionAnalytic
                      : pagedAndSorted
                    ).map((r, i) => (
                      <TableRow key={i}>
                        <TableCell>
                          {r.regionCode && (
                            <FlagImage
                              src={`/medias/img/flags/${r.regionCode.toLocaleLowerCase()}.png`}
                              alt={`flag_${r.regionCode}`}
                            />
                          )}
                          {r.regionLabel}
                        </TableCell>
                        <TableCell align="right">{kformat(r.value)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              {regionSerie.values.length > 9 && (
                <TableFooter>
                  <Pager
                    size="small"
                    total={regionSerie.values.length}
                    page={regionPage}
                    nbPerPage={NB_PER_PAGE}
                    selectPage={page => setRegionPage(page)}
                  />
                </TableFooter>
              )}
            </BoxSection>
          </FlexLine>
        </Wrapper>
      </Box>
    </Wrapper>
  )
}

const emptyRegionAnalytic = new Array(NB_PER_PAGE).fill({
  regionCode: '',
  color: '#CCCCCC',
  regionLabel: '',
  value: 0,
})

export const AnalyticsBox: StyledComponent<{ isLoading: boolean, ... }, *, *> = styled(Box)`
  ${(p: { isLoading: boolean, ... }) =>
    p.isLoading &&
    css`
      border-top: 3px solid #b8b8b8;
    `}
`

// @flow

import { type Map } from 'immutable'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { colors } from 'components/styled/tokens'

import { InlineEditorEditor } from './inline-editor.helper'

import { InactiveEditorContainer, InactiveEditorLabelContainer } from '../common.styles'
import { type TemplateRecord } from 'com.batch.redux/_records'

import { type MessageBuilderEditableField } from 'com.batch/message-builder/models/message-builder-field'

type InlineEditorProps = {
  messageId: string,
  isActive: boolean,
  onBlur: ([number, number]) => void,
  placeholder?: string,
  field: MessageBuilderEditableField,
  variant: 'a' | 'b',
  canUseTemplating: boolean,
  fullyInline?: boolean,
  selection: [number, number],
  onFocus: () => void,
  onActivateAtPos: number => void,
  onChange: string => void,
  value: string,
  templateCache: Map<string, TemplateRecord>,
  label?: string,
  hasCharCount?: boolean,
}

export const InlineEditor: React$AbstractComponent<InlineEditorProps, *> = React.memo(
  ({
    messageId,
    value,
    onBlur,
    selection,
    templateCache,
    onActivateAtPos,
    variant,
    onFocus,
    field,
    canUseTemplating,
    fullyInline = false,
    isActive,
    onChange,
    placeholder,
    label,
    hasCharCount = false,
  }: InlineEditorProps): React.Node => {
    const parsing = React.useMemo(
      () => templateCache.getIn([value, 'parsing'], false),
      [templateCache, value]
    )
    const text = React.useMemo(
      () => templateCache.getIn([value, 'results', 0, 'result'], value),
      [templateCache, value]
    )

    return isActive || parsing ? (
      <InlineEditorEditor
        messageId={messageId}
        isLoading={parsing}
        initValue={value}
        variant={variant}
        field={field}
        canUseTemplating={canUseTemplating}
        fullyInline={fullyInline}
        onBlur={onBlur}
        selection={selection}
        onChange={onChange}
        placeholder={placeholder}
        hasCharCount={hasCharCount}
      />
    ) : (
      <InactiveInlineEditor
        fullyInline={fullyInline}
        value={text}
        placeholder={placeholder}
        onFocus={onFocus}
        onActivateAtPos={onActivateAtPos}
        label={label}
        field={field}
      />
    )
  }
)

type InactiveInlineEditorProps = {
  fullyInline: boolean,
  value: string,
  placeholder: ?string,
  onFocus: () => void,
  onActivateAtPos: number => void,
  label?: string,
  field: MessageBuilderEditableField,
}
const InactiveInlineEditor = ({
  fullyInline,
  value,
  placeholder,
  onFocus,
  onActivateAtPos,
  label,
  field,
}: InactiveInlineEditorProps): React.Node => {
  const theme = useTheme()
  const id = React.useId()

  const onMouseUp = React.useCallback(() => {
    if (!theme?.disabledMode) {
      const selection = document.getSelection()
      onActivateAtPos(selection?.anchorOffset ?? 0)
    }
  }, [onActivateAtPos, theme?.disabledMode])

  const renderField = (
    <InactiveEditorContainer
      $fullyInline={fullyInline}
      $isPlaceholder={!value}
      onMouseUp={onMouseUp}
      data-testid={`${field}-placeholder`}
    >
      {value || placeholder}
      {!theme.disabledMode && (
        <input
          id={id}
          type="text"
          style={{
            textIndent: '-9999em',
            width: 0,
            height: 0,
            opacity: 0.01,
            position: 'fixed',
            top: 0,
          }}
          onFocus={onFocus}
        />
      )}
    </InactiveEditorContainer>
  )

  return label ? (
    <InactiveEditorLabelContainer data-testid={`${field}-placeholder`}>
      <label htmlFor={id} style={{ color: colors.textLight, fontWeight: 400, flexShrink: 0 }}>
        {label}
      </label>
      {renderField}
    </InactiveEditorLabelContainer>
  ) : (
    renderField
  )
}

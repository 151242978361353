// @flow

import * as React from 'react'
import { createPortal } from 'react-dom'

import { NotifClose } from 'components/campaign/preview/preview.styles'
import { Icon } from 'components/common/svg-icon'

import { PopinContext } from './popin.context'
// eslint-disable-next-line import/no-cycle
import { PopinBackdrop, PopinContainer } from './popin.styles'

export type PopinProps = {
  close: () => any,
  opened: boolean,
  showBackDropClose?: boolean,
  children: React.Node,
  fullscreen?: boolean,
  style?: { [string]: string | number },
  disableEscapeClose?: boolean,
}

export function Popin({
  close,
  opened,
  children,
  showBackDropClose = false,
  fullscreen = false,
  style,
  disableEscapeClose = false,
}: PopinProps): React.Node {
  const { count, updateCount } = React.useContext(PopinContext)
  React.useEffect(() => {
    const rootBody = document.getElementById('top')

    if (opened) {
      // Si la modale est fullscreen on cache la scrollbar de la vue située en dessous
      if (rootBody) rootBody.style.overflowY = 'hidden'
    } else {
      if (rootBody) rootBody.style.overflowY = 'auto'
    }

    return () => {
      const rootBody = document.getElementById('top')
      if (rootBody) rootBody.style.overflowY = 'auto'
    }
  }, [opened, fullscreen])
  /* 
    @todo be better
    this is an anti-pattern ; we store a counter on our popin context, that increments when opened changes. 
    this counter is listed as a dependency of the effect that evaluate the topmost popin, and bind the key listener
    accordingly
  */
  React.useEffect(() => {
    updateCount(count + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened])

  const dest = document.getElementById('modal-root')
  const [mouseDownTarget, setMouseDownTarget] = React.useState<null | EventTarget>(null)
  const onMouseDown = React.useCallback((event: MouseEvent) => {
    event.target && setMouseDownTarget(event.target)
  }, [])
  const ref = React.useRef()
  const onMouseUp = React.useCallback(
    (event: MouseEvent) => {
      if (ref.current === event.target && mouseDownTarget === event.target) {
        close()
      }
    },
    [close, mouseDownTarget]
  )

  const onKeyUp = React.useCallback(
    event => {
      if (event.keyCode === 27) {
        close()
      }
    },
    [close]
  )

  // Prevent click propagation pour que la popin n'interagisse pas avec les éléments en dessous comme par exemple un input en focus
  const stopPropagation = React.useCallback(event => event.stopPropagation(), [])

  React.useEffect(() => {
    if (document.body) {
      const collection = document.getElementsByClassName('js-modal-backdrop')
      const topMost = collection.item(collection.length - 1)
      if (opened && topMost === ref.current) {
        document?.body?.classList.add('modal-open')
        if (!disableEscapeClose) {
          window.addEventListener('keyup', onKeyUp)
        }
      } else {
        document?.body?.classList.remove('modal-open')
      }
    }

    return () => {
      if (!disableEscapeClose) {
        window.removeEventListener('keyup', onKeyUp)
      }
      document?.body?.classList.remove('modal-open')
    }
    // count is here to trigger a new evaluation when any popin changes it's opened state
    // so we always get correct topMost evaluation
  }, [onKeyUp, opened, count, disableEscapeClose])

  if (dest !== null) {
    return createPortal(
      <PopinBackdrop
        ref={ref}
        shown={opened}
        data-kind="modal-backdrop"
        className={opened ? 'js-modal-backdrop' : ''}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onClick={stopPropagation}
      >
        {opened && (
          <React.Fragment>
            {showBackDropClose && (
              <NotifClose fixed onClick={close}>
                <Icon size={14} icon="close" style={{ lineHeight: '13px' }} />
              </NotifClose>
            )}
            <PopinContainer role="dialog" aria-modal style={style} fullscreen={fullscreen}>
              {children}
            </PopinContainer>
          </React.Fragment>
        )}
      </PopinBackdrop>,
      dest
    )
  }
  return null
}

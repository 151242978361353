// @flow

import Immutable, { type Map } from 'immutable'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import { BoxBody, BoxFooter } from 'components/common/box'
import { Button, PermissionButton } from 'components/common/button'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import Loader from 'components/common/loader-legacy'
import {
  Form,
  InputWrapper,
  InputContainer,
  FormRow,
  FormActions,
  Input,
  Feedback,
  Select,
} from 'components/form'

import { Steps } from './steps'

import { type CompanyRecord, type RegionRecord } from 'com.batch.redux/_records'
import { getVat } from 'com.batch.redux/billing.api'
import { type BillingRecord } from 'com.batch.redux/billing.records'
import { regions } from 'com.batch.redux/campaign.selector'
import { persistBilling } from 'com.batch.redux/company'

const vatNumberRegex = new RegExp(/^([A-Z]{2})[A-Z]{0,1}([0-9, ]{8,14})/)

const sortedRegions = regions.sort((a, b) =>
  a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
)

type OwnProps = {
  company: CompanyRecord,
  prev?: () => void,
  next?: () => void,
}

type DispatchProps = {
  persistBilling: ({
    company: CompanyRecord,
    billingInfo: ?BillingRecord,
    paymentMethod: ?string,
    ...
  }) => Promise<BillingRecord>,
}

type BillingFormProps = { ...OwnProps, ...DispatchProps, ... }

type vatStateEnum = 'VALID' | 'INVALID' | 'UNKNOWN' | 'NA'
let validationCache: Map<string, { state: vatStateEnum, rate: number, ... }> = Immutable.Map()

const BillingFormRaw = ({ company, persistBilling, prev, next }: BillingFormProps) => {
  const [data, updateData] = useState<BillingRecord>(company.billing)
  const [errors, setErrors] = useState<Map<string, string>>(Immutable.Map())
  const [vatState, setVatState] = useState<vatStateEnum>('INVALID')
  const [vatValidationLoading, setVatValidationLoading] = useState<boolean>(false)

  const updateVatNumber = React.useCallback(
    (v: string) => {
      const temp = vatNumberRegex.exec(v)
      const country = Array.isArray(temp) && typeof temp[1] !== 'undefined' ? temp[1] : null
      if (country) {
        updateData(data.set('vatNumber', v).set('country', country))
      } else {
        updateData(data.set('vatNumber', v))
      }
    },
    [data]
  )

  // inutile dans l'absolu mais vu qu'on a pas la bonne company avec le vis ma vie pété actuellement...
  useEffect(() => {
    updateData(company.billing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.billing.timestamp])

  useEffect(() => {
    if (vatNumberRegex.test(data.vatNumber) || data.vatNumber === '') {
      const cacheKey = data.vatNumber + data.country
      if (validationCache.has(cacheKey)) {
        const cacheRes = validationCache.get(cacheKey, { rate: 0, state: 'NA' })
        updateData(data.set('vatRate', cacheRes.rate))
        setVatState(cacheRes.state)
      } else {
        setVatValidationLoading(true)
        getVat(data.vatNumber, data.country).then(({ rate, vatNumberState }) => {
          setVatValidationLoading(false)
          updateData(data.set('vatRate', rate))
          setVatState(vatNumberState)
          validationCache = validationCache.set(cacheKey, { rate, state: vatNumberState })
        })
      }
    } else {
      setVatState('NA')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.vatNumber, data.country])

  const untouched =
    data.companyName === company.billing.companyName &&
    data.firstname === company.billing.firstname &&
    data.lastname === company.billing.lastname &&
    data.address === company.billing.address &&
    data.address2 === company.billing.address2 &&
    data.city === company.billing.city &&
    data.zip === company.billing.zip &&
    data.vatNumber === company.billing.vatNumber &&
    data.country === company.billing.country

  // prevent navigation
  useEffect(() => {
    window.onbeforeunload = () => {
      return untouched ? null : 'Are you sure you want to leave without saving ?'
    }
    return () => {
      window.onbeforeunload = null
    }
  })

  const onSubmit = React.useCallback(() => {
    let err = []
    if (data.firstname.trim() === '')
      err.push(['firstname', 'Please enter a first name and a last name.'])
    if (data.lastname.trim() === '') err.push(['lastname', 'Please enter a last name.'])
    if (data.address.trim() === '') err.push(['address', 'Please enter an address.'])
    if (data.city.trim() === '') err.push(['city', 'Please enter a city.'])
    if (data.zip.trim() === '') err.push(['zip', 'Please enter a zip code.'])
    if (data.country.trim() === '') err.push(['country', 'Please enter a country.'])
    const vatNumber = data.vatNumber.trim()
    if (vatNumber !== '') {
      if (!vatNumberRegex.test(vatNumber)) err.push(['vatNumber', 'This is not a VAT Number.'])
      if (vatValidationLoading) {
        err.push([
          'vatNumber',
          'Please wait while we check your VAT Number with European Authorities.',
        ])
      } else {
        if (vatState === 'INVALID')
          err.push(['vatNumber', 'European authorities did not validate this VAT number.'])
        if (vatState === 'UNKNOWN')
          err.push([
            'vatNumber',
            'API used to validate VAT number is down, you can try again later or register as an individual and pay VAT.',
          ])
      }
    }

    const currentErrors = Immutable.Map(err)
    setErrors(currentErrors)
    if (currentErrors.size === 0) {
      persistBilling({ company, billingInfo: data, paymentMethod: null }).then(
        () => {
          if (typeof next === 'function') {
            next()
          }
        },
        () => {}
      )
    }
  }, [company, data, next, persistBilling, vatState, vatValidationLoading])
  return (
    <Loader loading={company.billing.loading} overlay>
      <Form horizontal onSubmit={onSubmit}>
        {prev ? (
          <React.Fragment>
            <BoxBody $padding style={{ maxHeight: 'calc(90vh - 180px)', overflowY: 'auto' }}>
              <FormPart
                data={data}
                errors={errors}
                updateData={updateData}
                vatValidationLoading={vatValidationLoading}
                updateVatNumber={updateVatNumber}
                vatState={vatState}
                untouched={untouched}
                popinMode
              />
            </BoxBody>
            <BoxFooter>
              <FlexLine grow>
                <FlexLineItem width={220}>
                  <Button type="button" kind="secondary" intent="neutral" onClick={prev}>
                    Previous
                  </Button>
                </FlexLineItem>
                <FlexLineItem grow={1}>
                  <Steps active={1} />
                </FlexLineItem>
                <FlexLineItem>
                  <Button type="submit" kind="primary" intent="action">
                    Continue
                  </Button>
                </FlexLineItem>
              </FlexLine>
            </BoxFooter>
          </React.Fragment>
        ) : (
          <FormPart
            data={data}
            errors={errors}
            updateData={updateData}
            vatValidationLoading={vatValidationLoading}
            updateVatNumber={updateVatNumber}
            vatState={vatState}
            untouched={untouched}
            popinMode={false}
          />
        )}
      </Form>
    </Loader>
  )
}
const countryTostring = (opt: ?RegionRecord) => opt?.label ?? ''
const FormPart = ({
  data,
  updateData,
  updateVatNumber,
  errors,
  vatState,
  untouched,
  vatValidationLoading,
  popinMode,
}: {
  data: BillingRecord,
  updateData: (d: BillingRecord) => void,
  updateVatNumber: (s: string) => void,
  errors: Map<string, string>,
  vatState: vatStateEnum,
  popinMode: boolean,
  untouched: boolean,
  vatValidationLoading: boolean,
  ...
}) => {
  const isAllowedToUpdate = useIsCurrentUserAllowedTo(['company', 'billing'])
  const updateVatNumberFromEvt = React.useCallback(
    evt => updateVatNumber(evt.target.value),
    [updateVatNumber]
  )
  const createOnChange = React.useCallback(
    (key: $Keys<BillingRecord>) => evt => updateData(data.set(key, evt.target.value)),
    [data, updateData]
  )
  const onCountryChange = React.useCallback(
    opt =>
      updateData(
        data.set(
          'country',
          typeof opt === 'object' && opt !== null && typeof opt.value === 'string' ? opt.value : ''
        )
      ),
    [data, updateData]
  )
  return (
    <React.Fragment>
      <InputWrapper label="Full name" feedback={errors.get('firstname') || errors.get('lastname')}>
        <FormRow>
          <Input
            type="text"
            id="name"
            placeholder="First name"
            value={data.firstname}
            invalid={errors.has('firstname')}
            onChange={createOnChange('firstname')}
            aria-label="First name"
          />
          <Input
            type="text"
            placeholder="Last name"
            value={data.lastname}
            invalid={errors.has('lastname')}
            onChange={createOnChange('lastname')}
            aria-label="Last name"
          />
        </FormRow>
      </InputWrapper>
      <InputWrapper label="Company" htmlFor="company" feedback={errors.get('company')}>
        <Input
          type="text"
          id="company"
          placeholder="Company"
          value={data.companyName}
          invalid={errors.has('company')}
          onChange={createOnChange('companyName')}
        />
      </InputWrapper>
      <InputWrapper
        label="VAT Number"
        htmlFor="vat"
        additional={
          <Feedback
            message={
              vatState === 'VALID'
                ? `You provided a valid VAT number; you will be considered as a company, ${
                    data.vatRate === 0
                      ? "and won't be charged VAT."
                      : `and be charged at the ${data.vatRate}% VAT rate. `
                  }`
                : vatState === 'UNKNOWN'
                  ? `We were unable to validate your VAT number; you will be considered as an individual,  ${
                      data.vatRate === 0
                        ? "and won't be charged VAT."
                        : `and be charged at the ${data.vatRate}% VAT rate. `
                    }`
                  : `You did not provide a valid VAT number; you will be considered as an individual, ${
                      data.vatRate === 0
                        ? "and won't be charged VAT."
                        : `and be charged at the ${data.vatRate}% VAT rate. `
                    }`
            }
          />
        }
        feedback={errors.get('vatNumber')}
      >
        <Input
          type="text"
          id="vat"
          placeholder="VAT Number"
          value={data.vatNumber}
          invalid={errors.has('vatNumber')}
          onChange={updateVatNumberFromEvt}
        />
      </InputWrapper>
      <InputWrapper label="Address" feedback={errors.get('address')}>
        <InputContainer invalid={errors.has('address')} style={{ flexDirection: 'column' }}>
          <input
            type="text"
            id="address"
            className="fs-exclude"
            placeholder="Address"
            value={data.address}
            onChange={createOnChange('address')}
            aria-label="Address line 1"
          />
          <hr />
          <input
            type="text"
            id="address2"
            className="fs-exclude"
            placeholder="Line 2"
            value={data.address2}
            onChange={createOnChange('address2')}
            aria-label="Address line 2"
          />
        </InputContainer>
      </InputWrapper>
      <InputWrapper label="Zip code / City" feedback={errors.get('zip') || errors.get('city')}>
        <FormRow>
          <Input
            type="text"
            id="zip"
            placeholder="Zip code"
            value={data.zip}
            invalid={errors.has('zip')}
            onChange={createOnChange('zip')}
            aria-label="Zip code"
          />
          <Input
            type="text"
            placeholder="City"
            value={data.city}
            invalid={errors.has('city')}
            onChange={createOnChange('city')}
            aria-label="City"
          />
        </FormRow>
      </InputWrapper>
      <InputWrapper label="Country" htmlFor="country" feedback={errors.get('country')}>
        <Select
          id="country"
          isSearchable
          invalid={errors.has('country')}
          isDisabled={vatValidationLoading}
          value={regions.find(r => r.value === data.country)}
          optionToString={countryTostring}
          options={sortedRegions}
          onChange={onCountryChange}
        />
      </InputWrapper>
      {!popinMode && (
        <FormActions>
          <PermissionButton
            kind="primary"
            intent="action"
            disabled={vatValidationLoading || untouched}
            isAllowed={isAllowedToUpdate}
          >
            Update
          </PermissionButton>
        </FormActions>
      )}
    </React.Fragment>
  )
}
// $FlowExpectedError maybe switch to useDispatch ?
export const BillingForm = connect<BillingFormProps, OwnProps, _, DispatchProps, _, _>(null, {
  // $FlowExpectedError maybe switch to useDispatch ?
  persistBilling,
})(BillingFormRaw)

// @flow

import Immutable, { List } from 'immutable'

import { computeRate } from './compute'

import {
  type OrchestrationStatsByStepRecord,
  OrchestrationStepFactory,
  StepStatsFactory,
} from 'com.batch/orchestration-analytics/models/orchestration-stats-by-step.record'
import { type OrchestrationStatsDynamicDimension } from 'com.batch/shared/infra/types/grpc-stats-service'

const coerceChannel = (channel: string): ?ChannelUntilCleanup => {
  switch (channel) {
    case 'email':
    case 'sms':
    case 'push':
      return channel
    default:
      return null
  }
}

export const parseStatsByStep = (
  stats: ?Array<OrchestrationStatsDynamicDimension>
): OrchestrationStatsByStepRecord => {
  const stepIndex = stats ? stats[0].dimensionNames.indexOf('step') : -1
  const channelIndex = stats ? stats[0].dimensionNames.indexOf('channel') : -1

  return new List().push(
    ...(stats && stepIndex !== -1 && channelIndex !== -1
      ? stats.map(({ dimensionValues, currentPeriod }) => {
          const sent = currentPeriod.sent ?? 0
          const delivered = currentPeriod.delivered ?? 0
          const click = currentPeriod.click ?? 0
          const uniqueClick = currentPeriod.uniqueClick ?? 0
          const bounce = currentPeriod.bounce ?? 0
          const open = currentPeriod.open ?? 0
          const uniqueOpen = currentPeriod.uniqueOpen ?? 0
          const unsubscribe = currentPeriod.unsubscribe ?? 0

          const step = dimensionValues[stepIndex]
          const channel = coerceChannel(dimensionValues[channelIndex])

          // computed values
          const bounceRate = computeRate(bounce, sent)
          const clickRate = computeRate(uniqueClick, delivered)
          const deliveredRate = computeRate(delivered, sent)
          const unsubscribeRate = computeRate(unsubscribe, delivered)
          const openRate = computeRate(uniqueOpen, delivered)

          return OrchestrationStepFactory({
            channel,
            step,
            stats: StepStatsFactory({
              delivered: Immutable.Record({ value: delivered, rate: deliveredRate })(),
              open: Immutable.Record({ value: open, rate: openRate })(),
              click: Immutable.Record({ value: click, rate: clickRate })(),
              bounce: Immutable.Record({ value: bounce, rate: bounceRate })(),
              unsubscribe: Immutable.Record({ value: unsubscribe, rate: unsubscribeRate })(),
            }),
          })
        })
      : [])
  )
}

// @flow

import { type ReduxAction } from 'com.batch.redux/_records'

export type AddLanguageAction = ReduxAction<
  'ADD_LANGUAGE',
  {
    messageId: string,
    lang: string,
    channel: ChannelUntilCleanup,
  },
>
export const addLanguage = (payload: {
  messageId: string,
  lang: string,
  channel: ChannelUntilCleanup,
}): AddLanguageAction => {
  return { type: 'ADD_LANGUAGE', payload }
}

export type RemoveLanguageAction = ReduxAction<
  'REMOVE_LANGUAGE',
  {
    messageId: string,
    lang: string,
    channel: ChannelUntilCleanup,
  },
>
export const removeLanguage = (payload: {
  messageId: string,
  lang: string,
  channel: ChannelUntilCleanup,
}): RemoveLanguageAction => {
  return { type: 'REMOVE_LANGUAGE', payload }
}

export type ClearLanguagesAction = ReduxAction<
  'CLEAR_LANGUAGES',
  { messageId: string, channel: ChannelUntilCleanup },
>
export const clearLanguages = (payload: {
  messageId: string,
  channel: ChannelUntilCleanup,
}): ClearLanguagesAction => {
  return { type: 'CLEAR_LANGUAGES', payload }
}

export type SetActiveLanguageAction = ReduxAction<'SET_ACTIVE_LANGUAGE', string>
export const setActiveLanguage = (lang: string): SetActiveLanguageAction => {
  return { type: 'SET_ACTIVE_LANGUAGE', payload: lang }
}

type ToggleMultilanguagePayload = {
  messageId: string,
  channel: ChannelUntilCleanup,
}
export type ToggleMultilanguageAction = ReduxAction<
  'TOGGLE_MULTILANGUAGE',
  ToggleMultilanguagePayload,
>
export const toggleMultilanguage = ({
  messageId,
  channel,
}: ToggleMultilanguagePayload): ToggleMultilanguageAction => {
  return { type: 'TOGGLE_MULTILANGUAGE', payload: { messageId, channel } }
}

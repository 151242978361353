// @flow
import { type List, type Map } from 'immutable'

import { parseBounceByDays } from '../infra/parses/parse-bounce-by-days'
import { parseBounceCategories } from '../infra/parses/parse-bounce-categories'
import { parseStatsByChannel } from '../infra/parses/parse-stats-by-channel'
import { parseStatsByProvider } from '../infra/parses/parse-stats-by-provider'
import { parseStatsByStep } from '../infra/parses/parse-stats-by-step'
import { type BounceDetailRecord } from '../models/bounce-detail-record'
import { type EmailProviderRecord } from '../models/orchestration-stats-by-provider.record'
import { type OrchestrationStatsByStepRecord } from '../models/orchestration-stats-by-step.record'
import {
  type OrchestrationStatsRecord,
  type BounceCategoryRecord,
} from '../models/orchestration-stats.record'

import { parseOrchestrationStatsToListStatsState } from 'com.batch/orchestration-list/infra/parses/parse-orchestration-stats-to-list-stats-state'
import { type OrchestrationListStatsStateRecord } from 'com.batch/orchestration-list/models/orchestration-list-stats-state.records'

export type DynamicDimension =
  | 'channel'
  | 'bounce_classification'
  | 'day'
  | 'mailbox_provider'
  | 'step'
  | 'content_language'
  | 'orchestration'

type Parser =
  | typeof parseStatsByChannel
  | typeof parseStatsByProvider
  | typeof parseStatsByStep
  | typeof parseBounceCategories
  | typeof parseBounceByDays
  | typeof parseOrchestrationStatsToListStatsState

export type parserReturns = $Call<Parser>

type GroupBy = {
  groupDimensionNames: Array<DynamicDimension>,
  includeFilterDimensions?: Array<{
    dimensionName: DynamicDimension,
    dimensionValues: Array<string>,
  }>,
}

const groupBy: {
  [key: string]: GroupBy,
} = {
  channel: {
    groupDimensionNames: ['channel'],
  },
  classification: {
    groupDimensionNames: ['bounce_classification'],
    includeFilterDimensions: [
      {
        dimensionName: 'channel',
        dimensionValues: ['email'],
      },
    ],
  },
  day: {
    groupDimensionNames: ['day'],
    includeFilterDimensions: [
      {
        dimensionName: 'channel',
        dimensionValues: ['email'],
      },
    ],
  },
  mailboxProvider: {
    groupDimensionNames: ['mailbox_provider'],
    includeFilterDimensions: [
      {
        dimensionName: 'channel',
        dimensionValues: ['email'],
      },
    ],
  },
  step: {
    groupDimensionNames: ['step', 'channel'],
  },
  orchestration: {
    groupDimensionNames: ['orchestration'],
  },
}

export const configs = {
  orchestrationStatsByChannel: {
    actionName: 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL',
    groupBy: groupBy.channel,
    parser: parseStatsByChannel,
  },
  orchestrationStatsByClassification: {
    actionName: 'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION',
    groupBy: groupBy.classification,
    parser: parseBounceCategories,
  },
  orchestrationStatsByDay: {
    actionName: 'FETCH_ORCHESTRATION_STATS_BY_DAY',
    groupBy: groupBy.day,
    parser: parseBounceByDays,
  },
  orchestrationStatsByProvider: {
    actionName: 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER',
    groupBy: groupBy.mailboxProvider,
    parser: parseStatsByProvider,
  },
  orchestrationStatsByStep: {
    actionName: 'FETCH_ORCHESTRATION_STATS_BY_STEP',
    groupBy: groupBy.step,
    parser: parseStatsByStep,
  },
  orchestrationListStats: {
    actionName: 'FETCH_ORCHESTRATIONS_LIST_DATA',
    groupBy: groupBy.orchestration,
    parser: parseOrchestrationStatsToListStatsState,
  },
}

// TYPES
type FetchDynamicStatsActionCommon = {
  payload: {
    tokens: List<string>,
  },
}
type FetchDynamicStatsSuccessActionCommon = {
  meta: {
    tokens: List<string>,
  },
}
type FetchDynamicStatsFailureActionCommon = {
  payload: {
    error: {
      message: string,
    },
    aborted: boolean,
  },
  meta: {
    tokens: List<string>,
  },
}

// FetchOrchestrationStatsByChannel
export type FetchOrchestrationStatsByChannelAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL',
  ...FetchDynamicStatsActionCommon,
}
export type FetchOrchestrationStatsByChannelSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_SUCCESS',
  payload: OrchestrationStatsRecord,
  ...FetchDynamicStatsSuccessActionCommon,
}
export type FetchOrchestrationStatsByChannelFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_FAILURE',
  ...FetchDynamicStatsFailureActionCommon,
}
export type FetchOrchestrationStatsByChannelActions =
  | FetchOrchestrationStatsByChannelAction
  | FetchOrchestrationStatsByChannelSuccessAction
  | FetchOrchestrationStatsByChannelFailureAction

// FetchOrchestrationStatsByClassification
export type FetchOrchestrationStatsByClassificationAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION',
  ...FetchDynamicStatsActionCommon,
}
export type FetchOrchestrationStatsByClassificationSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION_SUCCESS',
  payload: List<BounceCategoryRecord>,
  ...FetchDynamicStatsSuccessActionCommon,
}
export type FetchOrchestrationStatsByClassificationFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION_FAILURE',
  ...FetchDynamicStatsFailureActionCommon,
}
export type FetchOrchestrationStatsByClassificationActions =
  | FetchOrchestrationStatsByClassificationAction
  | FetchOrchestrationStatsByClassificationSuccessAction
  | FetchOrchestrationStatsByClassificationFailureAction

// FetchOrchestrationStatsByDay
export type FetchOrchestrationStatsByDayAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_DAY',
  ...FetchDynamicStatsActionCommon,
}
export type FetchOrchestrationStatsByDaySuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_DAY_SUCCESS',
  payload: Map<string, BounceDetailRecord>,
  ...FetchDynamicStatsSuccessActionCommon,
}
export type FetchOrchestrationStatsByDayFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_DAY_FAILURE',
  ...FetchDynamicStatsFailureActionCommon,
}
export type FetchOrchestrationStatsByDayActions =
  | FetchOrchestrationStatsByDayAction
  | FetchOrchestrationStatsByDaySuccessAction
  | FetchOrchestrationStatsByDayFailureAction

// FetchOrchestrationStatsByProvider
export type FetchOrchestrationStatsByProviderAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER',
  ...FetchDynamicStatsActionCommon,
}
export type FetchOrchestrationStatsByProviderSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER_SUCCESS',
  payload: List<EmailProviderRecord>,
  ...FetchDynamicStatsSuccessActionCommon,
}
export type FetchOrchestrationStatsByProviderFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER_FAILURE',
  ...FetchDynamicStatsFailureActionCommon,
}
export type FetchOrchestrationStatsByProviderActions =
  | FetchOrchestrationStatsByProviderAction
  | FetchOrchestrationStatsByProviderSuccessAction
  | FetchOrchestrationStatsByProviderFailureAction

// FetchOrchestrationStatsByStep
export type FetchOrchestrationStatsByStepAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_STEP',
  ...FetchDynamicStatsActionCommon,
}
export type FetchOrchestrationStatsByStepSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_STEP_SUCCESS',
  payload: OrchestrationStatsByStepRecord,
  ...FetchDynamicStatsSuccessActionCommon,
}
export type FetchOrchestrationStatsByStepFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_STEP_FAILURE',
  ...FetchDynamicStatsFailureActionCommon,
}

export type FetchOrchestrationStatsByStepActions =
  | FetchOrchestrationStatsByStepAction
  | FetchOrchestrationStatsByStepSuccessAction
  | FetchOrchestrationStatsByStepFailureAction

// FetchOrchestrationListStats
export type FetchOrchestrationsDataAction = {
  type: 'FETCH_ORCHESTRATIONS_LIST_DATA',
  ...FetchDynamicStatsActionCommon,
}
export type FetchOrchestrationsDataSuccessAction = {
  type: 'FETCH_ORCHESTRATIONS_LIST_DATA_SUCCESS',
  payload: Map<string, OrchestrationListStatsStateRecord>,
  ...FetchDynamicStatsSuccessActionCommon,
}
export type FetchOrchestrationsDataFailureAction = {
  type: 'FETCH_ORCHESTRATIONS_LIST_DATA_FAILURE',
  ...FetchDynamicStatsFailureActionCommon,
}

export type FetchOrchestrationsDataActions =
  | FetchOrchestrationsDataAction
  | FetchOrchestrationsDataSuccessAction
  | FetchOrchestrationsDataFailureAction
